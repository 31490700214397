/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.page-action-container {
  height: 100%;
  width: 100%;
}

.page-action-container__message {
  width: 100%;
}

.page-action-container__message__title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 0.3em;
}

.page-action-container__message__text {
  font-size: medium;
  padding: 16px 0px 0px 24px;
}

.page-action-container__message__button {
  margin-left: 24px;
}

.page-action-container__message__button Button {
  text-transform: uppercase;
  max-width: 225px;
}