.contactron-system-data-menu {
    display: grid;
    grid-template-columns: 1.1fr 0.8fr;
    grid-template-rows: auto;
    padding: 8px 8px 48px 8px;
    height: 100%;
    width: 100%;

    .contactron-system-data-menu__current {
        width: 100%;
    }

    .contactron-system-data-menu__power {
        width: 100%;
    }
}