.cockpit-a {
    border: 1px solid red;
    height: 100%;
}

.cockpit-contactron-station {
    display: grid;
    grid-template-rows: 30% 70%;
    grid-template-columns: 30% 40% 30%;
    grid-template-areas: 
        "devicestatus iostatus actualstatus"
        "modules modules modules";
    gap: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 16px);

    // .cockpit-contactron-station-item  {
    // }
    
    .cockpit-contactron-station__devicestatus {
        grid-area: devicestatus;
    }
    
    .cockpit-contactron-station__iostatus {
        grid-area: iostatus;
    }
    
    .cockpit-contactron-station__actualvalues {
        grid-area: actualstatus;

        .cockpit-contactron-station__actualvalues__menu {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 4px;
            gap: 4px;
        }
    }
    
    .cockpit-contactron-station__modules {
        grid-area: modules;
    }    
}

