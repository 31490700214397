/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.page-load-parameter-file {
  height: 72px;
  padding: 8px;
}

.page-load-parameter-file__title {
  font-size: var(--font-size-lg);
  font-weight: 600;
}

.page-load-parameter-file__button {
  height: 35px;
  padding: 0 4px;
  font-size: var(--font-size-xl);
  font-weight: 600;
  width: 100%;
}
// .page-select-parameter-file-container__status__logo {
//   margin-right: 20px;
// }

// .page-select-parameter-file-container__status__logo img {
//   height: 75px;
// }

// .page-select-parameter-file-container__title {
//   font-size: large;
//   font-weight: bold;
//   margin-bottom: 0.5em;
// }

// .page-select-parameter-file-container__text {
//   font-size: medium;
// }

// .page-select-parameter-file-container__messages {
//   padding-top: 2rem;
//   font-size: large;
// }
