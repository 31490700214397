.cockpit-caparoc-module-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cockpit-caparoc-module-container__channels {
    padding: 0, 8px, 0, 8px;
}
