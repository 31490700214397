/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.device-catalog-card-list {
  overflow-y: auto;
}

.device-catalog-card-item {
  height: 76px;
}

.device-catalog-card-list .list-group-item.active,
.device-catalog-card-list .list-group-item.active .device-catalog-card {
  background: var(--pri-button-bg) !important;
}