.trio3-channel-switch-block-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-end;
}
  
.trio3-channel-switch-block-container__header {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    fill: solid;
}

.trio3-channel-switch-block-container__switches {
    display: flex;
    column-gap: 8px;
}
  