.progressbar-container {
  width: 100%;
  height: 10px;
  background-color: var(--light-gray);
  border-radius: 10px;
}

.progressbar-bar {
  height: 10px;
  background: linear-gradient(225deg, var(--green), var(--corporate-blue));
  border-radius: 10px;
}