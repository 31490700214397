.navigation-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 64px auto 64px;
  grid-template-areas:
  "start"
  "navbuttons"
  "arrow";
}

.navigation-container__start {
  grid-area: start;
  justify-content: start;
  align-items: flex-end;
  cursor: pointer;
}

.navigation-container__start-row {
  width: 300px;
  height: 100%;
  display: grid;
}

.navigation-container__navbuttons {
  grid-area: navbuttons;
}

.navigation-container__navbuttons-title-format {
  color: var(--black);
  font-weight: 600;
  font-size: var(--font-size-lg);
  line-height: var(--line-height-base);
}

.navigation-container__navbuttons-row {
  height: 100%;
  width: 100%;
}

.navigation-container__arrow {
  grid-area: arrow;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.navigation-container__arrow-icon {
  display: flex;
  margin-left: 10px;
  margin-bottom: 15px;
  transform: rotate(0deg);
}

.navigation-container__arrow-icon.rotate {
  transform: rotate(180deg);
}