.accept-data-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    z-index: 10000;
    padding: 0px;
    width: 100%;
    height: 100%;
}

.accept-data-dialog__container {
    padding: 16px 40px 16px 40px;
    width: 606px;
    height: 418px;
    border-radius: 8px;
    background-color: var(--white);
    display: grid;
    grid-template-rows: 50px min-content minmax(0px, 180px) min-content;
}

.accept-data-dialog__container-header {
    display: flex;
    justify-content: center;
}

.accept-data-dialog__container-header__text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: var(--dark-gray);
}

.accept-data-dialog__container-body {
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
}

.accept-data-dialog__container-label {
    font-size: var(--font-size-lg);
    font-weight: 400;
    height: 46px;
    overflow-wrap: break-word;
    padding-top: 24px;
    margin-bottom: 16px;
}

.accept-data-dialog__container-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
}

.accept-data-dialog__container-buttons__f {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
}
