/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.save-protocol-document {
  height: 100%;
  width: 100%;
}

.save-protocol-document__caption__value {

}

.save-protocol-document__caption {
  font-size: large;
  font-weight: bold;
  font-weight: 600;
  font-size: 16px;
}

.save-protocol-document__document-name {
  font-size: var(--font-size-lg);
  font-weight: 400;
  padding: 0px 0px 0px 0px;
}

.save-protocol-document__document-text {
  font-size: var(--font-size-lg);
  font-weight: 400;
  padding: 16px 0px 0px 0px;
}

.save-protocol-document__document-text textarea {
  height: 1em;
  resize: none;
}
