.dip-switch-summary-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.3);
  position: absolute;
  z-index: 10000;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.dip-switch-summary-dialog__container {
  padding: 16px 40px 40px 40px;
  width: 1212px;
  height: 735px;
  border-radius: 8px;
  background-color: var(--white);
  display: grid;
  grid-template-columns: auto fit-content(1000px) 8px fit-content(1000px);
  grid-template-rows: 50px 8px 1fr 8px fit-content(1000px);
  grid-template-areas: 
    "header header header header"
    ". . . ."
    "body body body body"
    ". . . ."
    ". buttonAbort . buttonOk";
}

.dip-switch-summary-dialog__container--title {
  grid-area: header;
  font-size: 36px;
  font-weight: 600;
  justify-self: center;
  align-self: center;
}

.dip-switch-summary-dialog__container--body {
  grid-area: body;
  justify-self: center;
  align-self: start;
}

.dip-switch-summary-dialog__container--buttonAbort {
  grid-area: buttonAbort;
}

.dip-switch-summary-dialog__container--buttonOk {
  grid-area: buttonOk;
}