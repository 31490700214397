
.linearizationTableExpand-element {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 12px;
}

.linearizationTableExpand-button {
    width: fit-content;
  }

.linearizationTableExpand-icon {
    width: 15px;
    height: 10px;
}

.linearizationTableExpand-text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-left: 8px;
    color: #666667;
}
