.dip-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.dip-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30px;
  height: 60px;
  background: #c4c4c4;
  border-radius: 7px;
  position: relative;
  transition: background-color .2s;
}

.dip-switch-label--on {
  background: var(--corporate-blue);
}

.dip-switch-button {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.dip-switch-checkbox:checked + .dip-switch-button {
  transform: translateY(-30px);
}