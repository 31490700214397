.trio3-channel-switch-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.trio3-channel-switch-container__header {
  width: 100%;
  margin: 0px 0px 5px 0px;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
}

.trio3-channel-switch-container__header__label {
  display: inline-block;
}