/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.preview-editor-control__label p {
  font-size: var(--font-size-lg);
  text-align: end;
  overflow: hidden;
}

.preview-editor-control__unit p {
  font-size: var(--font-size-lg);
}

.preview-editor-control__value__input {
  font-size: var(--font-size-lg);
  line-height: 21px;
  text-align: end;
  padding: 0px 16px;
}

.preview-editor-control__value__input-field .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 10px;
}
