/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@mixin font-base {
    margin-bottom: 4px;
    margin-top: 4px;
    font-size: var(--font-size-xl);
    line-height: 1.25;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.offline-header-container__label-font {
    @include font-base;
    color: #666667;
    font-weight: 400;
}

.offline-header-container__value-font {
    @include font-base;
    color: #111111;
    font-weight: 400;
}

.offline-header-container__heading-font {
    color: var(--black);
    font-weight: 600;
    font-size: var(--font-size-xl);
    margin: 0px;
}

.offline-header-container__changes-font {
    padding: 5px;
    color: var(--black);
    font-size: var(--font-size-xl);
    font-weight: 400;
    margin-bottom: 0px;
    background-color: var(--changed-values);
}

.offline-header-container__container {
    display: grid;
    height: 149px;
    grid-template-columns: 133px 280px 380px 250px auto;
    grid-template-rows: 35px auto 35px;
    grid-template-areas:
    "image header header header header header"
    "image field1 field2 field3 field4 field5"
    "image footer footer footer footer footer";
    padding: 12px;
}

.offline-header-container__image {
    height: 121px;
    grid-area: image;
}

.offline-header-container__heading {
    grid-area: header;
}

.offline-header-container__one {
    grid-area: field1;
}

.offline-header-container__two {
    grid-area: field2;
}

.offline-header-container__three {
    grid-area: field3;
}

.offline-header-container__status {
    grid-area: field4;
}

.offline-header-container__image--fit-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.offline-header-container__line-limiter {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}
