/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.ApexGaugeControlRoot {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.ApexGaugeControlTitle {
  font-weight: 700;
  color: #22969E;
  text-align: center;
}

.apexcharts-canvas {
  background: var(--lighter-gray),
}

.apexcharts-text tspan {
  color: var(--corporate-blue);
}

.apexcharts-title-text {
  color: var(--corporate-blue);
  font-family: 'IBM Plex Sans';
}