.online-caparoc-station-control {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    "checkbox image"
    "buttons image";

    .online-caparoc-station-control__editor__checkboxes {
        grid-area: checkbox;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 100px 36px 0px 36px;

        .online-caparoc-station-control__editor__checkboxes-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .online-caparoc-station-control__editor__buttons {
        grid-area: buttons;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        padding: 72px 50px 36px 36px;

        .online-caparoc-station-control__editor__buttons-iolink {
            height: 58px;
            width: 100%;
        }
    }
    
    .online-caparoc-station-control__image {
        grid-area: image;
        display: flex;
        width: 100%;
        overflow: hidden;

        .online-caparoc-station-control__image__image {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 72px;
        }
    }
}
