/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.form-label {
  text-align: end;
}

.input-label {
  margin-top: 4px;
}

.form__select {
  padding-inline-end: 2em;
  padding-inline-start: 4px;
  border-bottom: 2px solid var(--dim-gray);
  padding-left: 8px;
  /* font-size: var(--font-size-xl); */
  font-size: var(--font-size-lg);
}

.form-control:focus, .form__select:focus {
  border-color: var(--corporate-blue);
}

.select-editor-control__value .form__select {
  height: 35px;
}

.select-editor-control__value .select-field .select-icon {
  position: absolute;
  top: 4px;
}

.select-editor-control__value .select-field .select-icon::after {
  content: "";
  height: 8px;
  width: 8px;
  display: inline-block;
  border-left: var(--corporate-blue) 3px solid;
  border-bottom: var(--corporate-blue) 3px solid;
  transform: rotate(-45deg);
}

.select-editor-control__label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  word-break: break-word;
  height: 35px;
}

.select-editor-control__label p {
  padding-top: 1px;
  font-size: var(--font-size-xl);
}

.select-editor-control__value {
  padding: 0;
}

.select-editor-control__unit {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: visible;
}

.select-editor-control__unit p {
  font-size: var(--font-size-xl);
}

