.select-device {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 56px auto;
    grid-template-areas:
    "title"
    "devices";

    .select-device-title {
        grid-area: title;
        
        .select-device-title-text {
            padding: 8px 0px 0px 8px;
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
        }
    }

    .select-device-devices {
        grid-area: devices;

        .select-device-devices-item-container {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .select-device-devices-item {
                display: flex;
                align-items: center;
                height: 32px;
                padding-left: 16px;
            }

            .select-device-devices-item-selected {
                background-color:#F2F4F6;
                color: black;
            }

            .select-device-devices-item-unselected {
                background-color: transparent;
                color: #9C9C9C;
            }
        }
    }

    .select-device-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}