/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.wiz-page-select-device__text_container {
  padding-bottom: 56px;
}

.wiz-page-select-device__text_container__line {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-lg);
  line-height: 21px;
}
