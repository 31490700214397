/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
 .applicationview-container {
  display: grid;
  height: 100%;
  width: 100%;
  margin: 0px;
  background-color: var(--lighter-gray);
  grid-template-columns: auto 300px;
  // grid-template-rows: calc(100% - 92px) 78px;
  grid-template-rows: calc(100% - 80px) 64px;
  grid-column-gap: 24px;
  grid-row-gap: 14px;
  padding: 0 4px 4px 4px;
}

.applicationview-container-layout {
  grid-template-areas:
  "main help"
  "foot help";
}

.applicationview-container-layout--no-help {
  grid-template-areas:
  "main main"
  "foot foot";
}

.applicationview-container-layout--no-buttons {
  grid-template-areas:
  "main help"
  "main help";
}

.applicationview-container-layout--no-help--no-buttons {
  grid-template-areas:
  "main main"
  "main main";
}

.applicationview-container__sidebar {
  grid-area: side;
  background-color: var(--white);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
}

.applicationview-container__content {
  grid-area: main;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 0 4px 4px 4px;
}

.applicationview-container__content__children {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.applicationview-container__content__message {
  position: absolute;
  width: 100%;
  bottom: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
}

.applicationview-container__content__message--hidden {
  display: none;
}

.applicationview-container__buttons {
  grid-area: foot;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.applicationview-container__helpbar {
  grid-area: help;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
}

.applicationview-container__helpbar--no-help,
.applicationview-container__buttons--no-buttons {
  display: none;
}
