.paper-control {
  display: flex;
  flex-direction: column;
  min-width: auto;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: stretch;
}

.paper-control__header {
  flex: 0 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 2px solid #EAEAEA;
}

.paper-control__header-title {
  color: var(--black);
  font-weight: 600;
  font-size: 24px;
  font-family: "IBM Plex Sans";
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paper-control__content {
  padding-top: 16px;
  padding-bottom: 8px;
  overflow: auto;
  .hmenu-layout-container {
    height: fit-content !important;
  }
}