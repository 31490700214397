$view-padding-left: 64px;

.startup-view-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: $view-padding-left;

    .startup-view-header__text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.startup-view-header__tools {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .startup-view-header__tools__button {
        padding-right: 24px;
        width: 50%;
    }

    .startup-view-header__tools__placeholder {
        width: 10%;
        border: 1px solid red;
    }

    .startup-view-header__tools__language {
        width: 40%;
        padding-right: 24px;
    }
}
