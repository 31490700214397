/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.cockpit-view-control {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.cockpit-view-control__layout-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.65fr auto;
    height: 100%;
    width: 100%;
    padding: 0 8px 8px 0;
}

.cockpit-view-control__layout-container--single-row {
    height: 100%;
    width: 100%;
    padding: 0 8px 8px 0;
}
