.hbar-chart-segment--red {
    stroke: var(--red);
}

.hbar-chart-segment--green {
    stroke: var(--medium-green);
}

.hbar-chart-segment--yellow {
    stroke: var(--yellow);
}

.hbar-chart-segment--grey {
    stroke: var(--light-gray);
}
