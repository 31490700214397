.command-block {
    display: flex;
    flex-direction: column;
    row-gap: 36px;

    .command-block-restart {
        padding-bottom: 24px;
    }

    .ack-relay-output {
        padding-bottom: 24px;
    }
}