.channel-status-trio__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
  
  .channel-status-trio__container-rectangle {
    display: flex;
    width: 10px;
    margin-right: 8px;
  }
  
  .channel-status-trio__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .channel-status-trio__title-current {
    height: 100%;
    width: 100%;
    display: flex;  
    flex-direction: column;
    justify-content: space-between;
    padding-top: 4px;
  }
  
  .channel-status-trio__title-channel {
    font-family: "IBM Plex Sans";
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    fill: solid;
    vertical-align: top;
  }
  
  .channel-status-trio__title-label {
    font-family: "IBM Plex Sans";
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    fill: solid;
    vertical-align: top;
    margin-bottom: 8px;
  }
  
  .channel-status-trio__title-value-unit {
    display: flex;
    flex-direction: row;
  }
  
  .channel-status-trio__title-value-unit_label {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    fill: solid;
  }
  
  .channel-status-trio__title-value-unit_value {
    font-family: "IBM Plex Sans";
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    fill: solid;
    margin-left: 4px;
  }
  
  .channel-status-trio__title-value-unit_unit {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    fill: solid;
    margin-left: 4px;
  }

.channel-status-trio__color-0 {
  background-color: var(--medium-gray);
}
  
.channel-status-trio__color-1 {
  background-color: var(--green);
}

.channel-status-trio__color-2 {
  background: var(--yellow);
}

.channel-status-trio__color-3 {
  background-color: var(--red);
}

.channel-status-trio__color-4-1-4Hz {
  -webkit-animation: blinkRedToYellowTrio 0.7s infinite;
  animation: blinkRedToYellowTrio 0.7s infinite;
}

.channel-status-trio__color-4-2-8Hz {
  -webkit-animation: blinkRedToYellowTrio 0.35s infinite;
  animation: blinkRedToYellowTrio 0.35s infinite;
}

.channel-status-trio__color-5-1-4Hz {
  -webkit-animation: blinkGrayToRedTrio 0.7s infinite;
  animation: blinkGrayToRedTrio 0.7s infinite;
}

.channel-status-trio__color-5-2-8Hz {
  -webkit-animation: blinkGrayToRedTrio 0.35s infinite;
  animation: blinkGrayToRedTrio 0.35s infinite;
}

.channel-status-trio__color-6-1-4Hz {
  -webkit-animation: blinkGrayToYellowTrio 0.7s infinite;
  animation: blinkGrayToYellowTrio 0.7s infinite;
}

.channel-status-trio__color-6-2-8Hz {
  -webkit-animation: blinkGrayToYellowTrio 0.35s infinite;
  animation: blinkGrayToYellowTrio 0.35s infinite;
}

@-webkit-keyframes blinkRedToYellowTrio { 
  0% { background-color: var(--red); }
  50% { background-color: var(--red); }
  100% { background-color: var(--yellow); }
}
@keyframes blinkRedToYellowTrio {
  0% { background-color: var(--red); }
  50% { background-color: var(--red); }
  100% { background-color: var(--yellow); }
}

@-webkit-keyframes blinkGrayToRedTrio {
  0% { background-color: var(--medium-gray); } 
  50% { background-color: var(--medium-gray); }
  100% { background-color: var(--red); }
}
@keyframes blinkGrayToRedTrio {
  0% { background-color: var(--medium-gray); }
  50% { background-color: var(--medium-gray); }
  100% { background-color: var(--red); }
}

@-webkit-keyframes blinkGrayToYellowTrio { 
  0% { background-color: var(--medium-gray); }
  50% { background-color: var(--medium-gray); }
  100% { background-color: var(--yellow); }
}
@keyframes blinkGrayToYellowTrio {
  0% { background-color: var(--medium-gray); }
  50% { background-color: var(--medium-gray); }
  100% { background-color: var(--yellow); }
}
