.FigmaBargraphControlVerticalChart-Bargraph{
  position: relative;
  display: flex;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.FigmaBargraphControlVerticalChart-Bargraph__TitleContainer {
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.FigmaBargraphControlVerticalChart-Bargraph__title {
  font-family:  "IBM Plex Sans";
  font-weight: 600;
  color: var(--black);
  text-align: center;
  padding-bottom: 0px;
}

.FigmaBargraphControlVerticalChart-Bargraph__subtitle {
  font-family:  "IBM Plex Sans";
  font-weight: 400;
  color: #666667;
  text-align: center;
}

.FigmaBargraphControlVerticalChart-Bargraph__ControlContainer {
  display: flex;
  height: fit-content;
  width: 70%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-bottom: 8px;
}

.FigmaBargraphControlVerticalChart-Bargraph__BarContainer {
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}

.FigmaBargraphControlVerticalChart-Bargraph__SegmentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.FigmaBargraphControlVerticalChart-Bargraph__TickContainer {
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.FigmaBargraphControlVertical-Bargraph__TickContainer-Tick {
  flex: 0 1 auto;
  background: #666667;
  margin-left: 5px;
}

.FigmaBargraphControlVertical-Bargraph__VerticalLineContainer {
  display: block;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.FigmaBargraphControlVertical-Bargraph__Line {
  flex: 0 1 auto;
  background-color: #666667;
}

.FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-item {
  flex: 0 1 auto;
}

.FigmaBargraphControlVerticalChart-Bargraph-ExtremeIndicatorContainer {
  height: 0px;
  background: var(--white);
}

.FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-extremeIndicator {
  background: var(--black);
}

.FigmaBargraphControlVerticalChart-Bargraph-LabelContainer {
  display: block;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label {
  flex: 0 1 auto;
  height: 0px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: right;
  color: #666667;
}

.FigmaBargraphControlVerticalChart-Segment-LowLow, .FigmaBargraphControlVerticalChart-Segment-HighHigh{
  background-color: var(--red);
}

.FigmaBargraphControlVerticalChart-Segment-Low, .FigmaBargraphControlVerticalChart-Segment-High{
  background-color: var(--yellow);
}

.FigmaBargraphControlVerticalChart-Segment-Normal{
  background-color: var(--medium-green);
}

.hideBargraphSegment{
  display: none;
}

.FigmaBargraphControlVerticalChart-Bargraph__ValuesContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.FigmaBargraphControlVerticalChart-Bargraph__CurrentValueContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
}

.FigmaBargraphControlVerticalChart-Bargraph__CurrentValueContainer-valueText {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  color: #333333;
  text-align: center;
}

.FigmaBargraphControlVerticalChart-Bargraph__CurrentValueContainer-unitText {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  color: #333333;
  text-align: center;
}

.FigmaBargraphControlVerticalChart-Bargraph__image {
  position: absolute;
  top: 0%;
  left: 108%;
}

.FigmaBargraphControlVerticalChart-Bargraph__overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.FigmaBargraphControlVerticalChart-Bargraph__image:focus-visible {
  outline-color: var(--button-border) !important;
}

.FigmaBargraphControlVerticalChart-Bargraph__ExtremesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  border-top: 2px solid #EAEAEA;
}

.FigmaBargraphControlVerticalChart-Bargraph__ExtremesContainer-text {
  width: fit-content;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  color: var(--gray);
  text-align: center;
}
