.event-monitor-trio-template {
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: 50% 30% auto;
  grid-template-areas: "column1 column2 column3";
}

.event-monitor-trio-template__header {
  border-bottom: 1px solid #E1E5E9;
}

.event-monitor-trio-template__column-1 {
  grid-area: column1;
  padding: 10px;
  border-right: 1px solid #E1E5E9;
}

.event-monitor-trio-template__column-2 {
  grid-area: column2;
  padding: 10px;
  border-right: 1px solid #E1E5E9;
}

.event-monitor-trio-template__column-3 {
  grid-area: column3;
  padding: 10px;
}