/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.page-save-protocol {
  height: 100%;
  width: 100%;
}

.page-save-protocol__caption {
  font-size: large;
  font-weight: bold;
}

.page-save-protocol__document-name {
  font-size: var(--font-size-lg);
  font-weight: 400;
  padding: 0px 0px 0px 0px;
}

.page-save-protocol__device-serial, .page-save-protocol__document-text {
  font-size: var(--font-size-lg);
  font-weight: 400;
  padding: 16px 0px 0px 0px;
}

.page-save-protocol__document-text textarea {
  height: 8.5em;
  resize: none;
}

.page-save-protocol__buttons__button Button {
  text-transform: uppercase;
  padding: 8px;
  height: 56px;
  font-size: var(--font-size-xl);
  font-weight: 600;
  overflow: hidden;
}

.page-save-protocol__document-text__input {
  padding: 0 8px;
  width: 100%;
  border-bottom: 2px solid var(--dim-gray);
  background-color: var(--lighter-gray);
  color: #3a3a3a;
  height: 35px;
}

.page-save-protocol__document-text__input:focus {
  border-bottom: 2px solid var(--corporate-blue);
}

