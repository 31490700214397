.compare-group-title {
    width: 50%;
}

.compare-group-title p {
    text-align: end;
    color: var(--black);
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: var(--line-height-base);
    padding-bottom: 16px;
}