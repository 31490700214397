.thumbwheel {
  height: 120px;
  width: 40px;
  position: relative;

  .rec {
    position: absolute;
    width: 100%;
    border: 2px solid #666A6E;
    background-color: white;
  }
  
  .rec1 {
    height: 120px;
  }
  
  .rec2 {
    height: 106px;
    top: 7px;
  }
  
  .rec3 {
    height: 80px;
    top: 20px;
  }
  
  .rec4 {
    height: 40px;
    top: 40px;
  }
  
  .thumbWheelValue {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: min-content;
    height: min-content;
    font-size: xx-large;
    font-weight: bold;
    color: #007C84;
  }
}

