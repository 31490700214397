.caparoc-channel-current-enumerator {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;

    .caparoc-channel-current-enumerator__label {
      padding-bottom: 24px;
      width: 120px;
      overflow: hidden;
    }

    .caparoc-channel-current-enumerator__control {
      padding-right: 8px;
      padding-left: 16px;
      width: 100%;
    }
  
    .caparoc-channel-current-enumerator__unit {
      padding-bottom: 24px;
    }
  }

