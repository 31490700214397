.tableControl-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tableControl-container-headers {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-end; */
    column-gap: 8px;
    width: 100%;
    height: 100%;
}

.tableControl-container-headers-element {
    height: 27px;
    align-items: left;
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 23px;
    color: var(--black);
}

.tableControl-container-bottom {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    row-gap: 8px;
}

.tableControl-container-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    row-gap: 8px;
}

.tableControl-container-bottom-row {
    display: flex;
    gap: 8px;
}

.tableControl-container-bottom-row-index {
    width: 32px;
}

.tableControl-container-bottom-row-index-element {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: var(--black);
}

.tableControl-container-bottom-row-element {
    width: 104px;
    flex-shrink: 1;
}

.tableControl-container-bottom-splitter {
    width: 4px;
}

.tableControl-container-bottom-splitter2 {
    width: 73px;
}
