.devicestatusled {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

$colours-list: var(--red) var(--yellow) var(--medium-green) var(--dim-gray) var(--black) var(--white); 
$colours-names: "red" "yellow" "green" "gray" "black" "white"; 
@each $clr in $colours-list {
    $i: index($colours-list, $clr); 
    $colour: $clr;
    $colour-name: nth($colours-names, $i); 
    @each $clr2 in $colours-list {
        $i2: index($colours-list, $clr2); 
        $colour2: $clr2;
        $colour-name2: nth($colours-names, $i2); 
        @-webkit-keyframes blink-#{$colour-name}-to-#{$colour-name2} { 
        $colour: $clr;
            0% { background-color: $colour; }
            50% { background-color: $colour; }
            100% { background-color: $colour2; }
        }
        @keyframes blink-#{$colour-name}-to-#{$colour-name2} { 
            $colour: $clr;
                0% { background-color: $colour; }
                50% { background-color: $colour; }
                100% { background-color: $colour2; }
            }
        .devicestatusled-blink-#{$colour-name}-to-#{$colour-name2}-1-4Hz {
            -webkit-animation: blink-#{$colour-name}-to-#{$colour-name2} 0.7s infinite;
                    animation: blink-#{$colour-name}-to-#{$colour-name2} 0.7s infinite;
        }
        .devicestatusled-blink-#{$colour-name}-to-#{$colour-name2}-2-8Hz {
            -webkit-animation: blink-#{$colour-name}-to-#{$colour-name2} 0.35s infinite;
                    animation: blink-#{$colour-name}-to-#{$colour-name2} 0.35s infinite;
        }
    }

    .devicestatusled-#{$colour-name} {
        background-color: $colour;
    }
}

.devicestatusled-blink-1-4Hz {
    -webkit-animation: blink 0.7s step-end infinite;
            animation: blink 0.7s step-end infinite;
}

.devicestatusled-blink-2-8Hz {
    -webkit-animation: blink 0.35s step-end infinite;
            animation: blink 0.35s step-end infinite;
}

@-webkit-keyframes blink { 50% { visibility: hidden; }}
        @keyframes blink { 50% { visibility: hidden; }}
