.contactron-commissioning-wizard {
    .page-group-process-data-header {
        width: 100%;
        height: 100%;

        .page-group-process-data-header-text {
            padding-bottom: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }

        .page-group-process-data-header-radio-buttons {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }
    }
}
