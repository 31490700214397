/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.AppRoot {
  height: 100%;
  display: grid;
  align-content: start;
  user-select: none;
  -webkit-user-select: none;
}

.AppRootBorder {
  border: 2.1px solid var(--black);
}

.AppTitlebar {
  -webkit-app-region: drag;
  -webkit-user-select: none;
  user-select: none;
  z-index: 9000000;
  background-color: var(--white);
  color: var(--black);
  height: 35px;
  border-bottom: 2.1px solid #e5e5e5;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(assets/images/clipx-engineer-window-titlebar.svg);
  display: flex;
  justify-content: space-between;
}

.AppTitlebar:not(.focused) {
  filter: opacity(50%);
}

.AppTitlebar.hidden {
  display: none;
}

.AppTitlebar button {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.AppTitlebarLogo {
  -webkit-app-region: none;
  transform-origin: top left;
  transform: scale(1.0);
  padding-left: 6px;
  height: 35px;
}

.AppTitlebarButtons {
  -webkit-app-region: none;
  transform-origin: right top;
  transform: scale(1.0);
  padding-right: 3px;
  height: 35px;
}

.AppTitlebarButtons > button:hover {
  background-color: #d0d3d4;
}

.AppTitlebarButtons > button {
  height: 35px;
  width: 44px;
}

.AppTitlebar.maximized #AppTitlebarIconWindowMaximize {
  display: none;
}
.AppTitlebar.maximized #AppTitlebarIconWindowRestore {
  display: inline-block;
}
.AppTitlebar:not(.maximized) #AppTitlebarIconWindowMaximize {
  display: inline-block;
}
.AppTitlebar:not(.maximized) #AppTitlebarIconWindowRestore {
  display: none;
}

.AppTitlebarTop {
  position: absolute;
  z-index: 9000000;
  height: 5.1px;
  width: 100%;
  -webkit-app-region: none;
  /* transparent area on top of titlebar to allow resizing
     of application window by not allowing to drag it there
  */
}

.AppContent {
  overflow: auto;
  height: calc(100vh - 35px - 0.1px);
  /* available vertical space for AppContent is calculated:
      100vh - Titlebar{heigth} - 2 * Root{border} - εpsilon
      100vh - Titlebar{ 35px } - 2 * Root{2.1px} - ε{0.1px}
     note: Titlebar{border} (2px) is part of Titlebar (35px)
     note: Root{border} (2px+ε=2.1px) is disabled by default
  */
}

.AppZoomDisplayContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000000;
}

.AppZoomDisplay {
  display: none;
  user-select: none;
  border: 6.1px solid var(--black);
  border-radius: .5em;
  background-color: grey;
  color: var(--white);
  opacity: 0.8;
  font-size: 40px;
  width: 7em;
  height: 2em;
  padding-top: .2em;
  text-align: center;
  margin-left: -3.5em;
  margin-top: -1em;
  transform: scale(1.0);
}
