/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.image-component-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
