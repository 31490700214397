.hdc-error-memory-control {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 32px auto 64px;
    padding: 8px;

    .hdc-error-memory-counter {
        overflow: hidden;
        padding: 8px 16px;
        color: #3a3a3a;
        font-size: 16px;
    }

    .hdc-error-memory-table {
        overflow: hidden;
        padding: 8px;
    }

    .hdc-error-memory-button {
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        align-content: center;
        padding: 8px;
    }
}