.motorstarter-online-card {
    overflow: hidden;
    padding: 8px;
    height: 100%;

    .motorstarter-online-card__title {
        overflow: hidden;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 16px;
    }

    .motorstarter-online-manual__body {
        width: 100%;
        height: 100%;
    }
}
