/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.parameter-group-child {
  padding: 8px 0px;
}

.parameter-group-description {
  padding-bottom: 24px;
  padding-right: 24px;
  font-size: 22px;
}