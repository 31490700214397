/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.backstage-menu {
  width: 140px;
  height: 100%;
  display: block;
  background-color: var(--lighter-gray); // #F2F4F6
}

.backstage-menu > .backstage-menu-item {
  min-height: 45px;
  padding: 13px 23px 13px;
}

.backstage-menu > .backstage-menu-item:hover {
  background-color: var(--light-gray); // #E0E6E9
  box-shadow: 0px 0px 16px rgba(32, 32, 32, 0.4);
}

.backstage-menu > .backstage-menu-item.selected {
  background-color: var(--medium-blue);
  color: var(--white);
}
