
.caparoc-system-settings-variable {
    display: flex;
    align-content: flex-start;
    align-items: center;
    line-height: 20px;
    padding-bottom: 8px;
    width: 100%;
}

.caparoc-system-settings-variable__label {
    font-size: 16px;
}
  
.caparoc-system-settings-variable__value {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    margin-left: 4px;
}
