/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.backstage-container {
  z-index: 8000000;
  width: 0;
  height: 0;
  overflow: visible;
}

.backstage-container > .backstage {
  width: 100vw;
  height: calc(100vh - 35.1px);
  // note: on zooming, height of elements with CSS class '.AppContent'
  // is resized by method 'compensateZooming()' in 'AppTitlebar.tsx'
  overflow: hidden;
  margin-top: -100vh;
  display: grid;
  grid-template-columns: 140px auto;
}

.backstage-container > .backstage.opened {
  margin-top: 0vh;
}

.backstage-container > .backstage.collapsed {
  margin-left: -100vw;
}

.backstage-container > .backstage.slide {
  transition-duration: 0.9s;
}

.backstage-container .backstage-menubar {
  background-color: var(--lighter-gray);
  color: #3A3A3A;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.backstage-container .backstage-menubar > *:nth-child(1) {
  height: 92px;
  padding: 24px 27px 0;
}

.backstage-container .backstage-menubar .backstage-close {
  display: inline-block;
}

.backstage-container .backstage-content {
  background-color: var(--white);
  padding: 25px 0px;
  transition-duration: 0s;
  width: 0;
}

.backstage-container .backstage-content.opened {
  transition-duration: 0.5s;
  width: 100%;
  padding: 25px 24px;
  overflow: auto;
}

.backstage-container .backstage-content {
  color: var(--black);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.backstage-container .backstage-content .full {
  width: 100%;
  height: 100%;
}

.backstage-container .backstage-content h1 {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 24px;
}

.backstage-container .backstage-content h2 {
  margin-top: 40px;
  font-size: 24px;
}

.backstage-container .backstage-content h3 {
  margin-top: 32px;
  font-size: 20px;
}

blockquote {
  border-left: 4px solid var(--light-gray);
  padding-left: 12px;
  margin-bottom: 24px;
}
