.Chart-control {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Chart-control-legend {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Chart-control-legend-item {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 4px solid;
}

.Chart-control-graphics {
  /* width: 80%;
  height: 80%; */
  position: relative;
}

.Chart-control-plotArea {
  width: 100%;
  height: 100%;
  position: relative;
}

.Chart-control-svg {
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #676667;
  transform: rotateX(180deg);
}

.Chart-control-gridline {
  stroke: #E0DFE1;
  stroke-width: 1px;
}

.Chart-control-line {
  stroke-width: 4px;
  stroke-linecap: round;
}

.Chart-control-leadingLine {
  stroke: #2F2F2F;
  stroke-width: 1px;
  stroke-dasharray: 5;
}

.Chart-control-graphics-verticalBar-left {
  position: absolute;
  top: 0%;
  right: 100%;
}

.Chart-control-graphics-verticalBar-right {
  position: absolute;
  top: 0%;
  left: 100%;
}

.Chart-control-graphics-horizontalBar {
  position: absolute;
  width: 100%;
  top: 100%;
}

.Chart-control-verticalAxis-minimalistic {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  align-content: center;
  width: 0px;
}

.Chart-control-horizontalAxis-minimalistic {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--black);
}