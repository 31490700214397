.password-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    z-index: 20000;
    padding: 0px;
    width: 100%;
    height: 100%;
}

.password-dialog__container {
    padding-top: 16px;
    padding-left: 40px;
    padding-right: 40px;
    width: 606px;
    height: 372px;
    border-radius: 8px;
    background-color: var(--white);
}

.password-dialog__container__row {
    height: 40px;
}

.password-dialog__container__header {
    display: flex;
    justify-content: center;
}

.password-dialog__container__header__text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: var(--dark-gray);
}

.password-dialog__container__body {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.password-dialog__container__body-label {
    font-size: var(--font-size-lg);
    font-weight: 400;
    padding: 0px 0px 0px 0px;
    height: 46px;
    overflow-wrap: break-word;
}

.password-dialog__container__body-buttons {
    height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.password-dialog__container__body-buttons__f {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
}
