.maxRegression-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  width: 305px;
}


.maxRegression-top-label {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    height: 24px;
    color: var(--black);
    fill: solid;
    padding-right: 8px;
    white-space: nowrap;
  	overflow: hidden;
}

.maxRegression-element-label {
    width: 85px;
    min-width: 85px;
}
