/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@import '../../../assets/styles/variables';

@mixin loading-progress-spinner-base($size, $border) {
  display: inline-block;
  position: relative;
  width: $size;
  height: $size;

  div {
    display: block;
    position: absolute;
    width: $size - ($border + $border);
    height: $size - ($border + $border);
    margin: $border;
    border-radius: 50%;
    border: $border solid transparent;
    border-top-color: green;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:first-child {
      -webkit-animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) -.45s infinite,
        loading-progress-spinner-color 1.5s infinite;
      animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) -.45s infinite,
        loading-progress-spinner-color 1.5s infinite;
    }

    &:nth-child(2) {
      -webkit-animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) -.3s infinite,
        loading-progress-spinner-color 1.5s infinite;
      animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) -.3s infinite,
        loading-progress-spinner-color 1.5s infinite;
    }

    &:nth-child(3) {
      -webkit-animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) -.15s infinite,
        loading-progress-spinner-color 1.5s infinite;
      animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) -.15s infinite,
        loading-progress-spinner-color 1.5s infinite;
    }

    &:nth-child(4) {
      -webkit-animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) 0s infinite,
        loading-progress-spinner-color 1.5s infinite;
      animation:
        loading-progress-spinner-rotation 1.5s cubic-bezier(.5,0,.5,1) 0s infinite,
        loading-progress-spinner-color 1.5s infinite;
    }
  }

  .loading-progress-spinner-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    .loading-progress-spinner-container__title {
      font-size: var(--font-size-xxl);
    }

    .loading-progress-spinner-container__title--hidden {
      display: none;
    }
   
    .loading-progress-spinner-container__progress {
      font-size: var(--font-size-xxl);
    }

    .loading-progress-spinner-container__progress--hidden {
      display: none;
    }
  }
}

@-webkit-keyframes loading-progress-spinner-rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes loading-progress-spinner-rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-webkit-keyframes loading-progress-spinner-color {
  0% {
    border-color: var(--green) transparent transparent
  }

  50% {
    border-color: var(--corporate-blue) transparent transparent
  }

  to {
    border-color: var(--green) transparent transparent
  }
}

@keyframes loading-progress-spinner-color {
  0% {
    border-color: var(--green) transparent transparent
  }

  50% {
    border-color: var(--corporate-blue) transparent transparent
  }

  to {
    border-color: var(--green) transparent transparent
  }
}

.loading-progress-spinner {
  @include loading-progress-spinner-base(32px, 3px);
  .loading-progress-spinner-container {
    .loading-progress-spinner-container__title {
      display: none;
    }
  }
}

.loading-progress-spinner--contactron-station {
  @include loading-progress-spinner-base(50px, 6px);
  .loading-progress-spinner-container {
    .loading-progress-spinner-container__title {
      display: none;
    }
  }
}

.loading-progress-spinner--lg {
  @include loading-progress-spinner-base(48px, 4.5px);
  .loading-progress-spinner-container {
    .loading-progress-spinner-container__title {
      display: none;
    }
  }
}

.loading-progress-spinner--xl {
  @include loading-progress-spinner-base(180px, 16px);
  .loading-progress-spinner-container {
    .loading-progress-spinner-container__title {
      font-size: 18px; // --font-size-xl
    }
  }
}

.loading-progress-spinner--xxl {
  @include loading-progress-spinner-base(300px, 24px);
  .loading-progress-spinner-container {
    .loading-progress-spinner-container__title {
      font-size: 18px; // --font-size-xxl
    }
  }
}

.loading-progress-spinner--device-card {
  @include loading-progress-spinner-base($wizard-device-card-height - $wizard-device-card-height * 0.3825, 16px);
  .loading-progress-spinner-title {
    display: show;
  }
}
