/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.standardview-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 145px auto;
    grid-template-areas:
    "head"
    "main";
    gap: 8px;
    height: 100%;
    width: 100%;
    background-color: var(--lighter-gray);
    margin: 0px;
}

.standardview-container-no-header {
    grid-template-areas:
    "main"
    "main";
}

.standardview-header {
    grid-area: head;
}

.standardview-content {
    grid-area: main;
    background-color: var(--white);
    overflow: hidden;
}

.standardview-hidden {
    display: none;
}
