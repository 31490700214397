/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.form-label {
  text-align: end;
}

.input-label {
  margin-top: 4px;
}

.form__select {
  padding-inline-end: 2em;
  padding-inline-start: 4px;
  border-bottom: 2px solid var(--dim-gray);
  padding-left: 8px;
  /* font-size: var(--font-size-xl); */
  font-size: var(--font-size-lg);
}

.form-control:focus, .form__select:focus {
  border-color: var(--corporate-blue);
}

