
.device-status-variable-icon__container {
  display: flex;
  align-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-bottom: 8px;
  width: 100%;
}

.device-status-variable-icon__container-space {
  width: 28px;
}

.device-status-variable-icon__container-label {
  display: flex;
  font-style: normal;
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  white-space: pre-wrap;
}

.device-status-variable-icon__container-icon {
  padding-left: 8px;
}

  