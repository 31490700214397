/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.wizard-stepper {
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
}

.wizard-stepper .wizard-stepper__line {
  height: 12px;
  margin: 8px;
  background-color: var(--corporate-blue);
}

.wizard-stepper .wizard-stepper__line * {
  height: 12px;
  background-color: var(--medium-gray);
}

.wizard-stepper > .wizard-stepper__labels {
  margin: 8px 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-xxl);
  font-weight: 300;
  line-height: 30px;
}

.wizard-stepper > .wizard-stepper__labels > * {
  width: 0;
  overflow: visible;
}

.wizard-stepper > .wizard-stepper__labels > * > * {
  width: 200px;
  margin-left: calc(200px / -2);
  text-align: center;
}

.wizard-stepper > .wizard-stepper__labels > *:first-child > * {
  width: 200px;
  margin-left: calc(0px - 8px);
  text-align: left;
}

.wizard-stepper__labels__label--error {
  background-color: rgba(255, 11, 11, 0.5);
}

.wizard-stepper__labels__label--modified {
  background-color: var(--changed-values);
}

.wizard-stepper > .wizard-stepper__labels > *:last-child > * {
  width: 200px;
  margin-left: calc(0px - 200px + 8px);
  text-align: right;
}

.wizard-stepper > .wizard-stepper__dots {
  margin-top: -26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-sm);
  font-weight: 600;
}

.wizard-stepper > .wizard-stepper__dots > * {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid var(--corporate-blue);
  background-color: var(--corporate-blue);
  display: flex;
  align-items: center;
  place-content: center;
}

.wizard-stepper > .wizard-stepper__dots > .wizard-stepper__dots__dot--completed {
  color: var(--white);
}

.wizard-stepper > .wizard-stepper__dots > .wizard-stepper__dots__dot--performing {
  background-color: var(--white);
}

.wizard-stepper > .wizard-stepper__dots > .wizard-stepper__dots__dot--pending {
  border-color: var(--medium-gray);
  background-color: var(--medium-gray);
}
