/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@import '../../assets/styles/variables';

.page-select-device-container {
  height: 100%;
  width: 100%;
}

.page-select-device__devicelist {
  grid-area: devicelist;
}

.page-select-device__devicelist-title {
  grid-area: title;
  height: 24px;
}

.page-select-device__devicelist-title p {
  font-weight: 600;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-base);
}
