.trio3-event-monitor__list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.trio3-event-monitor__list-item__icon {
  margin-right: 8px;
}

.trio3-event-monitor__list-item__label {
  padding-top: 2px;
}

.trio3-event-monitor__list-item__label--pending {
  padding-top: 2px;
  text-decoration: line-through;
}

.trio3-event-monitor__list-item__button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.trio3-event-monitor__list-item__button__icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.trio3-event-monitor__list-item--pending {
  cursor: default;
  visibility: hidden;
  text-decoration: line-through;
}