.select-device-dialog {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 132px auto;
    grid-template-areas: 
    "header"
    "content";
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}

.select-device-dialog__header {
    grid-area: header;
}

.select-device-dialog__content {
    grid-area: content;
    overflow: auto;
}

.select-device-dialog__title p {
    font-size: var(--font-size-xl);
    font-weight: 600;
    line-height: 23px;
    font-style: normal;
    text-transform: uppercase;
    color: rgba(162, 167, 169, 1);
}

.select-device-dialog__category__title {
    overflow: hidden;
}

.select-device-dialog__search__filter-button Button {
    width: 35px;
    height: 35px;
    padding: 0;
    background: var(--white);
}

/* .select-device-dialog__content__card-list {
    width: calc(100% - 40px);
} */
