$view-padding-left: 64px;

.startup-view-content--no-device {
    grid-template-areas: 
        "device-list driver-hint";
}

.startup-view-content--device {
    grid-template-areas: 
        "device-list device-list";
    
    .startup-view-content__driver_message {
        display: none;
    }
}

.startup-view-content {
    display: grid;
    grid-template-columns: 1260px auto;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: row;
    padding-left: $view-padding-left;
    // overflow-y: hidden;
    // overflow-x: auto;

    .startup-view-content__device-list {
        grid-area: device-list;
        display: flex;
        flex-direction: row;
        padding: 8px 0px;
    }

    .startup-view-content__driver_message {
        grid-area: driver-hint;
        display: flex;
        flex-direction: column;

        .startup-view-content__driver_message__text {
            width: 80%;
            padding: 4px 16px 16px 0;
        }

        .startup-view-content__driver_message__button {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .startup-view-content__driver_message__button__button {
                padding-right: 24px;
                width: 50%;
        
                .btn {
                    text-transform: none;
                }
            }
        }
    }
}
