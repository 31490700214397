/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.text-input-field .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 9px;
}

.text-input-field .textfield__input--edited {
  background-color: var(--changed-values) !important;
}

