.ChannelStatusControl__Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.ChannelStatusControl__Container-Rectangle {
  display: flex;
  width: 10px;
  margin-right: 8px;
}

.ChannelStatusControl__TitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ChannelStatusControl__TitleContainer-current {
  height: 100%;
  width: 100%;
  display: flex;  
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4px;
}

.ChannelStatusControl__TitleContainer-channel {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  fill: solid;
  vertical-align: top;
}

.ChannelStatusControl__TitleContainer-label {
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  fill: solid;
  vertical-align: top;
  margin-bottom: 8px;
}

.ChannelStatusControl__TitleContainer-valueUnit {
  display: flex;
  flex-direction: row;
}

.ChannelStatusControl__TitleContainer-valueUnit-label {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  fill: solid;
}

.ChannelStatusControl__TitleContainer-valueUnit-value {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  fill: solid;
  margin-left: 4px;
}

.ChannelStatusControl__TitleContainer-valueUnit-unit {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  fill: solid;
  margin-left: 4px;
}

.ChannelStatusControl_Led-red {
  background-color: var(--red);
}

.ChannelStatusControl_Led-yellow {
  background-color: var(--yellow);
}

.ChannelStatusControl_Led-green {
  background-color: var(--green);
}

.ChannelStatusControl_Led-gray {
  background-color: var(--gray);
}

.ChannelStatusControl_Led-white {
  background-color: var(--white);
}

.ChannelStatusControl_Led-black {
  background-color: var(--black);
}