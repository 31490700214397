/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/IBMPlexSans-Thin.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-Thin.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: url(../fonts/IBMPlexSans-ThinItalic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-ThinItalic.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/IBMPlexSans-ExtraLight.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-ExtraLight.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: url(../fonts/IBMPlexSans-ExtraLightItalic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-ExtraLightItalic.woff2) format("woff2");
}


@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/IBMPlexSans-Light.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-Light.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/IBMPlexSans-LightItalic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-LightItalic.woff2) format("woff2");
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/IBMPlexSans-Regular.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-Regular.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/IBMPlexSans-Italic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-Italic.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/IBMPlexSans-Medium.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-Medium.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: url(../fonts/IBMPlexSans-MediumItalic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-MediumItalic.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/IBMPlexSans-SemiBold.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-SemiBold.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: url(../fonts/IBMPlexSans-SemiBoldItalic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-SemiBoldItalic.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/IBMPlexSans-Bold.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-Bold.woff2) format("woff2");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/IBMPlexSans-BoldItalic.ttf) format('truetype'),
       url(../fonts/IBMPlexSans-BoldItalic.woff2) format("woff2");
}

@font-face {
  font-family: 'Gill Sans MT';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/GillSansMT.ttf) format('truetype');
}

#root {
  --line-height-base: 1.25;
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 18px;
  --font-size-xxl: 22px;
  --font-size-xxxl: 26px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
