$view-padding-left: 64px;

.startup-view {
    display: grid;
    grid-template-columns: 1200px 124px auto;
    grid-template-rows: 88px 400px auto;
    grid-template-areas: 
        "header header tools"
        "device-list device-list device-list"
        "wizard wizard wizard";
    width: 100%;
    height: 100%;

    .startup-view__text__title {
        font-weight: 400;
        font-size: 26px; // var(--font-size-xxxl);
        line-height: 1.25;
        height: 40px;
    }
    .startup-view__text__subtitle {
        font-weight: 400;
        font-size: 18px; // var(--font-size-xl);
        line-height: 1.25;
        height: 28px;
    }

    .startup-view__header {
        grid-area: header;
    }
    .startup-view__header__tools {
        grid-area: tools;
    }
    .startup-view__content {
        grid-area: device-list;
    }

    .startup-view__wizard {
        grid-area: wizard;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 80px auto;
        grid-template-areas: 
            "header"
            "buttons";
        width: 100%;
        height: 100%;
        background: var(--lighter-gray);
        padding-left: $view-padding-left;

        .startup-view__wizard__header {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
        }
        .startup-view__wizard__buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            height: fit-content;
            overflow: auto;

            .startup-button {
                width: 604px;
                height: 88px;
                margin: 16px 32px 8px 0px;
            }
              
            .startup-button button {
                width: 100%;
            }
            
            .startup-button:not(.startup-button-clickable) button {
                cursor: not-allowed !important;
            }
        }
    }
}
