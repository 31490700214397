.contactron-commissioning-wizard {
    width: 100%;
    height: 100%;
    
    .page-station-select-process-data {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 0px 32px 16px 128px;
    }
}