/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.page-preview-group {
    width: 100%;
}

.page-preview-group__label {
    font-weight: 600;
    font-size: var(--font-size-lg);
    text-align: end;
}

.page-preview-group__label--align-end {
    text-align: end;
}

.page-preview-group__label--align-start {
    text-align: start;
}

.page-preview-group__children {
    width: 100%;
}

.page-preview-group__children .row {
    flex-wrap: nowrap;
}
