.coldjunction-layout-container {
    width: 888px;
    border: 1px solid #E1E5E9;
    overflow: hidden;
}

.coldjunction-layout-container2 {
    width: 700px;
    border: 1px solid #E1E5E9;
    overflow: hidden;
}

.coldjunction-layout-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 42px;
    border: 1px solid #E1E5E9;
    background-color: #E1E5E9;
    overflow: hidden;

    .coldjunction-layout-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .designation {
        width: 124px;
        padding-left: 4px;
    }

    .currently-set {
        width: 480px;
    }

    .currently-set2 {
        width: 494px;
        padding-left: 10px;
    }
}

.coldjunction-layout-controls {
    display: flex;
    flex-direction: column;
}
