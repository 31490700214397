.forcedeviceenable-container {
    width: 100%;
    height: 50px; 
    display: flex;
    align-items: center;
    justify-content: space-between;

    .forcedeviceenable-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        padding-left: 8px;
    }

    .forcedeviceenable-icon {
        width: 32px;
        height: 20px;
        padding-right: 8px;
    }

    .forcedeviceenable-button_disabled {
        opacity: 0.5; /* Reduce opacity to indicate disabled state */
        pointer-events: none; /* Disable pointer events to prevent interaction */
        cursor: not-allowed; 
    }
}

.forcedeviceenable-color--true {
    border: 3px solid;
    border-color: #FFA800;
}

.forcedeviceenable-color--false {
    border: 1px solid;
    border-color: var(--black);
}
