.trio3-channel-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.trio3-channel-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 22px;
  height: 30px;
  background: var(--medium-gray);
  border-radius: 3px;
  position: relative;
  transition: background-color .2s;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgb(32 32 32 / 30%);
}

/* blinking freq 1.4Hz 2.8Hz
0 - gray, 1 - green, 2 - yellow, 3 - red,
4 - blink red -> yellow, 5 - blink to red, 6 - blink to yellow */

.trio3-channel-switch-1 {
  background: radial-gradient(var(--white), 10%, var(--green));
  box-shadow: 1px 1px 10px var(--green);
}

.trio3-channel-switch-2 {
  background: radial-gradient(var(--white), 10%, var(--yellow));
  box-shadow: 1px 1px 10px var(--yellow);
}

.trio3-channel-switch-3 {
  background: radial-gradient(var(--white), 10%, var(--red));
  box-shadow: 1px 1px 10px var(--red);
}

.trio3-channel-switch-4-1-4Hz {
  -webkit-animation: trio3blinkRedToYellow 0.7s infinite;
  animation: trio3blinkRedToYellow 0.7s infinite;
}

.trio3-channel-switch-4-2-8Hz {
  -webkit-animation: trio3blinkRedToYellow 0.35s infinite;
  animation: trio3blinkRedToYellow 0.35s infinite;
}

.trio3-channel-switch-5-1-4Hz {
  -webkit-animation: trio3blinkGrayToRed 0.7s infinite;
  animation: trio3blinkGrayToRed 0.7s infinite;
}

.trio3-channel-switch-5-2-8Hz {
  -webkit-animation: trio3blinkGrayToRed 0.35s infinite;
  animation: trio3blinkGrayToRed 0.35s infinite;
}

.trio3-channel-switch-6-1-4Hz {
  -webkit-animation: trio3blinkGrayToYellow 0.7s infinite;
  animation: trio3blinkGrayToYellow 0.7s infinite;
}

.trio3-channel-switch-6-2-8Hz {
  -webkit-animation: trio3blinkGrayToYellow 0.35s infinite;
  animation: trio3blinkGrayToYellow 0.35s infinite;
}

@-webkit-keyframes trio3blinkRedToYellow { 
  0% { background: radial-gradient(var(--white), 10%, var(--red)); box-shadow: 1px 1px 10px var(--red); }
  50% { background: radial-gradient(var(--white), 10%, var(--red)); box-shadow: 1px 1px 10px var(--red); }
  100% { background: radial-gradient(var(--white), 10%, var(--yellow)); box-shadow: 1px 1px 10px var(--yellow); }
}
@keyframes trio3blinkRedToYellow {
  0% { background: radial-gradient(var(--white), 10%, var(--red)); box-shadow: 1px 1px 10px var(--red); }
  50% { background: radial-gradient(var(--white), 10%, var(--red)); box-shadow: 1px 1px 10px var(--red); }
  100% { background: radial-gradient(var(--white), 10%, var(--yellow)); box-shadow: 1px 1px 10px var(--yellow); }
}

@-webkit-keyframes trio3blinkGrayToRed {
  0% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); } 
  50% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); }
  100% { background: radial-gradient(var(--white), 10%, var(--red)); box-shadow: 1px 1px 10px var(--red); }
}
@keyframes trio3blinkGrayToRed {
  0% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); }
  50% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); }
  100% { background: radial-gradient(var(--white), 10%, var(--red)); box-shadow: 1px 1px 10px var(--red); }
}

@-webkit-keyframes trio3blinkGrayToYellow { 
  0% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); }
  50% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); }
  100% { background: radial-gradient(var(--white), 10%, var(--yellow)); box-shadow: 1px 1px 10px var(--yellow); }
}
@keyframes trio3blinkGrayToYellow {
  0% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%);  }
  50% { background-color: var(--medium-gray); box-shadow: 0px 4px 8px rgb(32 32 32 / 30%); }
  100% { background: radial-gradient(var(--white), 10%, var(--yellow)); box-shadow: 1px 1px 10px var(--yellow); }
}

