/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@import '../../assets/styles/variables';

.page-select-catalog-device__devicelist-title {
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 16px;
}

.page-select-catalog-device__devicelist-title p {
  font-weight: 600;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-base);
}
