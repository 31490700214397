.VerticalAxis-control {
  height: 100%;
  width: fit-content;
}

.VerticalAxis-control-label {
  height: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.VerticalAxis-control-labelText {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5C5C5C;
}

.flotLeft {
  float: left;
}

.floatRight {
  float: right;
}
