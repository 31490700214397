/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.compareParameterViewHeader__title {
  background-color: var(--light-gray);
  font-size: var(--font-size-lg);
  font-weight: bold;
}

#CompareParameterViewExtFile {
  width: 0;
  height: 0;
  overflow: hidden;
}

.x-padding-12 {
  padding-left: 12px;
  padding-right: 12px;
}