/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.startup-button-container {
    padding: 0px;
    height: 100%;
    width: 100%;
}

.startup-button-title-format {
    color: var(--black);
    font-weight: 600;
    font-size: var(--font-size-xl);
    line-height: var(--line-height-base)
}

.startup-button-subtitle-format {
    color: var(--black);
    font-weight: 400;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base)
}

.startup-button-root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
    background-color: var(--white);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 96px auto 110px;
}

.startup-button-text-container {
    height: 100%;
    text-transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.startup-button-left-icon-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.startup-button-left-icon {
    width: auto;
    height: auto;
}

.startup-button-right-icon-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
