.forceswitchingoutput-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .forceswitchingoutput-title {
        font-weight: 600;
        font-size: 24px;
    }

    .forceswitchingoutput-text {
        padding-top: 16px;
    }

    .forceswitchingoutput-buttons-icon {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
        align-items: center;
        padding-top: 16px;

        .forceswitchingoutput-buttons {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
        }

        .forceswitchingoutput-icon {
            width: 116px;
            height: 28px;
        }
    }

    .forceswitchingoutput-button {
        font-size: 19.2px;
        font-weight: 600;
        line-height: 1.27;
        width: 315px;
        height: 100%;
    }
}