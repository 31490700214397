.caparoc-paper-control {
  display: flex;
  flex-direction: column;
  min-width: auto;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: stretch;
}

.caparoc-paper-control__status {
  height: 6px;
  width: 100%;
}

.caparoc-paper-control__status--red {
  background-color: var(--red);
}

.caparoc-paper-control__status--green {
  background-color: var(--medium-green);
}

.caparoc-paper-control__status--gray {
  background-color: var(--gray);
}

.caparoc-paper-control__status--yellow {
  background-color: var(--yellow);
}

.caparoc-paper-control__right-break {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  height: 100%;
  background-color: var(--white);
  border-radius: 6px 0 0 6px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  clip-path: inset(-100px 0px -100px -100px);
  align-self: stretch;
  background-image: url("./assets/triangle_paper_frame_right.png");
  background-position: right top;
  background-repeat: repeat-y;
  background-size: 10px 40px;
}

.caparoc-paper-control__left-break {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  height: 100%;
  background-color: var(--white);
  border-radius: 0 6px 6px 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  clip-path: inset(-100px -100px -100px 0px);
  align-self: stretch;
  background-image: url("./assets/triangle_paper_frame_left.png");
  background-position: left top;
  background-repeat: repeat-y;
  background-size: 10px 40px;
}

.caparoc-paper-control__header {
  flex: 0 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 2px solid #EAEAEA;
}

.caparoc-paper-control__header-title {
  color: var(--black);
  font-weight: 600;
  font-size: 24px;
  font-family: "IBM Plex Sans";
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caparoc-paper-control__content {
  padding-top: 16px;
  padding-bottom: 8px;
  overflow: auto;
  .hmenu-layout-container {
    height: fit-content !important;
  }
}