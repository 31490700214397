/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.input-field {
  margin: 0;
}

.parameter-control-label {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-end;
  word-break: break-word;
}

.parameter-control-label p {
  font-size: var(--font-size-lg);
}

.parameter-control-value {
  padding: 0;
}

/* .parameter-control-value > * {
  margin-bottom: .5rem;
} */

.parameter-control-unit {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: visible;
}

.parameter-control-unit p {
  font-size: var(--font-size-lg);
}
