.contactron-motorstarter-online {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 16px;

    .tab-content {
        overflow: hidden;
        height: 100%;   
    }

    .contactron-motorstarter-online__nameplate {
      width: 100%;
      height: 100%;
      overflow: hidden;
  }

    .contactron-motorstarter-online__event {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .contactron-motorstarter-online__control {
        display: grid;
        width: 100%;
        height: 100%;
        overflow: hidden;
        grid-template-rows: 25% 35% 40%;
        grid-template-columns: 30% 55% 15%;
        grid-template-areas: 
        "systemstatus manual picture"
        "operational systemdata picture"
        "trend trend trend";

        .contactron-motorstarter-online__item {
            padding: 4px;
            height: 100%;
        }

        .contactron-motorstarter-online__systemstatus {
            grid-area: systemstatus;
            padding-left: 16px;
        }

        .contactron-motorstarter-online__manual {
            grid-area: manual;
        }

        .contactron-motorstarter-online__systemdata {
            grid-area: systemdata;
        }

        .contactron-motorstarter-online__operational {
            grid-area: operational;
            padding-left: 16px;
        }

        .contactron-motorstarter-online__trend {
            grid-area: trend;
            padding-left: 16px;
        }

        .contactron-motorstarter-online__picture {
            grid-area: picture;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                height: 300px;
            }
        }
    }

    .contactron-system-data-menu__power {
        width: 200px;
    }

    .contactron-system-data-menu__current {
        width: 300px;
    }
}