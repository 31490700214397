/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.loading-splash-screen__header {
    font-size: var(--font-size-xxxl);
    font-weight: 700;
}

.loading-splash-screen__spinner {
    height: 150px;
}

.loading-splash-screen__text {
    font-size: var(--font-size-lg);
}

.loading-splash-screen__download_setup__header {
    width: 80%;
    padding-top: 40px;
    padding-bottom: 8px;
    font-size: var(--font-size-xxl);
    font-weight: 300;
    text-align: center;
}

.loading-splash-screen__download_setup__text {
    font-size: var(--font-size-lg);
    padding: 8px;
}