.table-row-module__address {
    text-align: start;
    vertical-align: top;
    padding-top: 10px;
}

.table-row-module__name {
    text-align: start;
    vertical-align: top;
    padding-top: 10px;
    overflow: hidden;
    max-width: 320px;
}

.table-row-module__control {
    text-align: start;
    vertical-align: top;
}
