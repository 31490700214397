.contactron-commissioning-wizard {
    .page-station-addressing-table-area {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .page-station-addressing-table-area__table {
            font-weight: bold;
            overflow: auto;
        }
    }
}