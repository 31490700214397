.psr-uni__manual_configuration__step {
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-rows: auto;
  overflow-y: auto;

  .psr-uni__manual_configuration__step__index {
    padding: 8px;
  }

  .psr-uni__manual_configuration__step__text {
    padding: 8px;
  }
}