.coldjunction-line-compensation-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #E1E5E9;
    column-gap: 48px;
    padding-top: 16px;
    padding-left: 8px;
    overflow: hidden;

    .coldjunction-line-compensation-designation-container {
        height: 35px;
        width: 64px;
        padding-top: 18px;
        display: flex;

        .coldjunction-line-compensation-designation {   
            font-weight: 400;
            font-size: 16px;
        }
    }  

    .coldjunction-line-compensation-input,
    .coldjunction-line-compensation-devicevar {
        width: 136px;
    }

    .coldjunction-line-compensation-button-container {
        padding-top: 4px;
    }

    .coldjunction-line-compensation-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 248px;
        height: 35px;

        .coldjunction-line-compensation-text {
            font-weight: 400;
            font-size: 16px;
            color: #007C84;
            padding-left: 8px;
            text-transform: none !important;
        }

        .coldjunction-line-compensation-button img {
            margin-left: 16px;
            margin-right: 8px;
        }
    }  
}
