.tableElement-splitter1 {
    width: 4px;
}

.tableElement-splitter2 {
    width: 8px;
}

.tableElement-label {
    width: 103px;
}

.tableElement-index {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: right;
    color: var(--black);
}