.x3phasen-header {
    display: flex;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
}

.x3phasen-header__title-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.x3phasen-header__title-container__title {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-size: 18px;
    color: var(--black);
    text-align: center;
    overflow: hidden;
}
