/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.DeviceCardList {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.CardListItem {
  padding: 0px 32px 0px 0px;
  width: 318px;
}

.DeviceCardList > .CardListItem:last-child {
  padding: 0px;
  width: 286px;
}
