/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.action-progress-title {
  font-weight: bold;
  font-size: large;
}

.action-progress-step__status--completed {
  color: var(--corporate-blue);
  font-size: var(--font-size-xl);
}

.action-progress-step__status--completed > img {
  width: 30px;
  height: 26px;
}

.action-progress-step__status--running {
  height: 30px;
  display: flex;
}

.action-progress-step__status--pending {
  color: var(--gray);
}

.action-progress-step__status--failed {
  color: var(--red);
  font-size: 26px;
  padding-left: 4px;
}
