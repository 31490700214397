.contactron-commissioning-wizard {
    .page-station-addressing-table {
        .tr-device-type-container {
            position: relative;

            .tr-device-type-container__hint-text--hidden {
                display: none;
            }

            .tr-device-type-container__hint-text {
                position: absolute;
                font-size: 10px;
                font-weight: 400;
                top: 8px;
                right: 4px;
                text-align: right;
                width: 100%;
                overflow: hidden;
                color: rgba(0, 170, 0, 1);
            }
        }
    }
}