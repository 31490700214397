.device-process-data-input-output-element {
    display: flex;
    flex-direction: row;
    align-items: center;

    .device-process-data-input-output-element__tooltip {
        padding-left: 8px;
        padding-right: 8px;
    }
}
