.flex-container {
  display: flex;
  flex-direction: row;
  height: 20px;
  margin: -10px;
}

.flex-container img {
  margin-top: 4px;
  margin-right: 10px;
}

.flex-container span {
  height: 20px;
  font-size: 1.05rem;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  text-transform: none;
}

#play-pause-button input[type="checkbox"] {
  display: none;
}