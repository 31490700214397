.HorizontalAxis-control {
  width: 100%;
  margin-top: 8px;
}

.HorizontalAxis-control-labelsContainer {
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}

.HorizontalAxis-control-labelsContainer-label {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;  
  width: 0px;
}

.HorizontalAxis-control-labelsContainer-labelText {
  flex: 0 1 auto;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5C5C5C;
}

.HorizontalAxis-control-name {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--black);
}