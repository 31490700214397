/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.accordion-header {
  background-color: var(--white);
}

.accordion-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-height: 350px;
  overflow-y: auto;
}

.accordion-content__card {
  margin: 0;
  padding: 0px 0px 24px 0px;
  width: 100%;
}

.accordion-content-line {
  width: 18px;
  border-right: 2px solid var(--corporate-blue);
  margin: 0px 0px 4px;
  padding: 0;
}

.accordion-content {
  width: 100%;
  padding: 8px 16px;
  overflow-y: auto;
}
