.contactron-commissioning-wizard {
    width: 100%;
    height: 100%;

    .page-device-naming-table {
        width: 100%;

        .page-device-naming-table-header {
            background-color: #E1E5E9;

            .page-device-naming-table-header__address {
                width: 24px;
                padding: 0px 16px;
            }
        }

        tr {
            border: 1px solid #E1E5E9;
            height: 40px;
        }

        td {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        tbody td:first-child,
        thead th:first-child {
          padding-left: 8px;
        }
    }
}