.contactron-commissioning-wizard {
    width: 100%;
    height: 100%;
    
    .page-station-identification {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 60% auto;
        grid-template-rows: auto;
    }
}