.contactron-sq-device-status  {
    .DeviceStatusControl_content {
        padding: 0;
        
        .devicestatusled  {
            width: 16px;
            height: 16px;
            border-radius: 0;
        }
    }
}