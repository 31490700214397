.selectprocessdataparent-container {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 22% 78%;
  grid-template-rows: auto 48px 64px;
  grid-template-areas:
  "deviceSelection processDataSelection"
  "progressbarinput processDataSelection"
  "progressbaroutput processDataSelection";
}

.selectprocessdataparent-device-selection {
  grid-area: deviceSelection;
  background-color: var(--white);
  overflow-y: auto;
}

.selectprocessdataparent-process-data-selection {
  grid-area: processDataSelection;
  background-color: var(--lighter-gray);
}

.selectprocessdataparent-progressbar--input {
  grid-area: progressbarinput;
}

.selectprocessdataparent-progressbar--output {
  grid-area: progressbaroutput;
}

// .selectprocessdataparent-progressbar {
//   display: flex;
//   flex-direction: column;
//   padding: 8px 16px 0 16px;

//   .selectprocessdataparent-progressbar-text {
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 24px;
//   }
// }

.selectprocessdataparent-apply-for-all-devices {
  grid-area: applyForAllDevices;
  background-color: var(--lighter-gray);
  padding-left: 16px;
  display: flex;
  align-items: center;
}
