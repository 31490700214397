.svg-gauge-control {
  width: 100%;
  height: 100%;
}

.svg-gauge-control__overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
  