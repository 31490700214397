.contactron-commissioning-wizard {
    .page-station-addressing {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 25% 24% 6% auto;
        grid-template-rows: 20% auto;
        grid-template-areas:
        "header header . table"
        "control control . table";

        .page-station-addressing__header {
            grid-area: header;
            padding: 0px 8px 8px 16px;
        }
        .page-station-addressing__control {
            grid-area: control;
            padding: 8px 8px 24px 16px;
        }
        .page-station-addressing__table {
            grid-area: table;
            padding: 0px 16px 24px 8px;
            overflow: auto;
        }
    }
}