.checkbox-control__container {
  height: 30px;
}

.checkbox-control__container__input {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-control__container input[type="checkbox"] {
  opacity: 0;
}

.checkbox-control__container label::before{
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid var(--corporate-blue);
}

.checkbox-control__container--bkc label::before{
  background-color: var(--changed-values) !important;
}

.checkbox-control__container label::after {
  content: "";
  height: 8px;
  width: 18px;
  display: inline-block;
  border-left: var(--corporate-blue) 3px solid;
  border-bottom: var(--corporate-blue) 3px solid;
  transform: rotate(-45deg);
}

.checkbox-control__container label {
  position: absolute;
  height: 30px;
  top: -2px;
  left: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-size-xl);
  font-family: 'IBM Plex Sans';
  margin: 0;
}

.checkbox-control__container label::before,
.checkbox-control__container label::after {
  position: absolute;
}

/* Outer-box*/
.checkbox-control__container label::before {
  top: 0px;
  left: -38px;
}

/*Checkmark*/
.checkbox-control__container label::after {
  left: -31px;
  top: 9px;
}

/*Hide the checkmark by default*/
.checkbox-control__container input[type="checkbox"] + label::after {
  content: none;
}

/*Unhide the checkmark on the checked state*/
.checkbox-control__container input[type="checkbox"]:checked + label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox-control__container input[type="checkbox"]:focus + label::before {
  outline: var(--corporate-blue) auto 5px;
}

.checkbox-control__container input[type="checkbox"]:focus {
  border-radius: 5px;
}
