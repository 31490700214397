/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

:root {
  --white: #ffffff;
  --gray: #636363;
  --lighter-gray: #f2f4f6;
  --light-gray: #e0e6e9;
  --medium-gray: #ccd0d2;
  --dim-gray: #a2a7a9;
  --gray: #666a6e;
  --dark-gray: #202020;
  --black: #000000;
  --corporate-blue: #0098a1;
  --medium-blue: #00838a;
  --dark-blue: #007982;
  --pink: #d8124f;
  --red: #ed0a19;
  --yellow: #ffa800;
  --green: #96be0d;
  --medium-green: #09ae3b;
  --royal-purple: #7030a0;
  --deep-sky-blue: #00b0f0;
  --sapphire: #002060;
  --magenta: #e11d78;
}
