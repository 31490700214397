.online-caparoc-checkbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.online-caparoc-checkbox--disabled {
  .checkbox-control__container {
    label::before, label::after {
      filter: invert(50%) !important;
    }
  }
}