/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.page-group {
  width: 100%;
  margin-top: 32px;
}

.page-group__label {
  width: 50%;
  padding: 8px 16px;
  font-weight: 600;
  font-size: var(--font-size-xl);
  margin: 0;
}

.page-group__label--align-end {
  text-align: end;
}

.page-group__label--align-start {
  text-align: start;
}

.page-group__children {
  width: 100%;
}

.page-group__children .row {
  flex-wrap: nowrap;
}
