.compare-table-parameter-mid-control__sign-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.compare-table-parameter-mid-control__sign-container2 {
    display: flex;
    height: 45px;
}

.compare-table-parameter-mid-control__sign {
    color: var(--corporate-blue);
    width: 20px;
}

.compare-table-control__button-container {
    display: flex;
    padding-top: 32px;
}

.compare-table-control__button {
    padding: 0px 8px !important;
    height: 38px;
    margin: 0px 2px;
    margin-bottom: 2px;
}

.compare-table-control__button__arrow {
    color: var(--corporate-blue);
    padding: 0;
    margin: 0;
}

.compare-table-control__button--hidden {
    visibility: hidden;
}

.compare-table-control__left {
    display: flex;
    flex: row;
    justify-content: flex-end;
}

.compare-table-control__left__space {
    display: flex;
    width: 70px;
}
