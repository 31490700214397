.dropdown-icon-link {
  background-color: var(--lighter-gray);
  font-weight: 400;
  border-bottom: var(--dim-gray) 1px solid;
}

.dropdown-icon-link:link, .dropdown-icon-link:visited {
  color: var(--dark-gray);
  padding: 7px 13px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
}

.dropdown-icon-link:hover, .dropdown-icon-link:active {
  border-bottom: var(--corporate-blue) 1px solid;
}

.flex-container-dropdown {
  display: flex;
  flex-direction: row;
}

.dropdown-icon-container {
  width: 21px;
  position: relative;
}

.dropdown-icon {
  height: 8px;
  width: 8px;
  position: absolute;
  top: 5px;
  left: 13px;
  border-left: var(--corporate-blue) 2px solid;
  border-bottom: var(--corporate-blue) 2px solid;
  transform: rotate(-45deg);
}

.checkbox-trend__input {
  display: none;
}

.checkbox-trend {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px var(--corporate-blue) solid;
  margin-right: 4px;
}

.divide-line {
  height: 2px;
  background-color: #e3e3e3;
}

.notice, .divide-line {
  margin-bottom: 12px;
}

.dropdown-menu-trend {
  white-space: nowrap;
}