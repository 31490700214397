.selectprocessdataparent-progressbar {
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0 16px;
  
    .selectprocessdataparent-progressbar-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }