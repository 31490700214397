.thumbwheelwithbuttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 64px;

  .thumbwheelwithbuttons-label {
    display: flex;
    justify-content: center;
    margin: 10px 0 5px 0;
    font-size: var(--font-size-xl);
    white-space: nowrap;
    font-weight: 700;
  }

  .thumbwheelwithbuttons-button {
    display: flex;
    justify-content: center;
  }
  
  .thumbwheelwithbuttons-thumbwheel {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
}
