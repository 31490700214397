.wiz-reset-container {
    /* overflow: auto; */
}

.wiz-reset-container__description {
}

.wiz-reset-container__title {
    font-weight: 600;
    line-height: 21px;
}

.wiz-reset-container__preview__control {
    height: calc(100% - 36px);
    overflow: auto;
}

.wiz-reset-container__preview {
    overflow: hidden;
}