.tableColumnHeader-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    width: 230px;
}

.tableColumnHeader-container-label {
    width: 103px;
}

.tableColumnHeader-container-label-unit{
    height: 27px;
    align-items: left;
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    color: var(--black);
}

.tableColumnHeader-container-splitter1 {
    width: 18px;
}

.tableColumnHeader-container-splitter2 {
    width: 8px;
}

