.accept-data-template {
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: 50% 50%;
  grid-template-areas: "column1 column2";
}

.accept-data-template__header {
  border-bottom: 1px solid #E1E5E9;
}

.accept-data-template__column-1 {
  grid-area: column1;
  padding-right: 10px;
  border-right: 1px solid #E1E5E9;
}

.accept-data-template__column-2 {
  grid-area: column2;
  padding-left: 10px;
}