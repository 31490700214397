/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.form-label {
  text-align: end;
}

.input-label {
  margin-top: 0px;
}

.parameter-control-label {
  height: 35px;
}

.parameter-control-label p {
  height: 28px;
  font-size: var(--font-size-xl);
}

.input-range-editor-control .col-1 {
  max-width: none;
}

.textfield__input {
  border-bottom: 2px solid var(--dim-gray);
  height: 35px;
}

.parameter-control-value .input-field {
  height: 35px;
}

.parameter-control-value input
{
    color: var(--dark-gray);
    font-size: var(--font-size-lg);
    padding-left: 12px;
}

.parameter-control-value .input-field .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 9px;
}

.textfield__input--edited {
  background-color: var(--changed-values) !important;
}
