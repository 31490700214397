.contactron-commissioning-wizard {
    .page-station-addressing-table {
        width: 100%;

        .page-station-addressing-table-header {
            background-color: #E1E5E9;
        }

        .page-station-addressing-table-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .tr--standard {
            border: 1px solid #E1E5E9;
        }

        .tr--highlight {
            border: 2px solid rgba(0, 170, 0, 1);
        }

        tr {
            height: 40px;
        }

        td {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        tbody td:first-child,
        thead th:first-child {
          padding-left: 8px;
        }
    }
}