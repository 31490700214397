.device-status-variable-value__container {
    display: flex;
    align-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 8px;
    width: 100%;
}

.device-status-variable-value__container-space {
    width: 28px;
}

.device-status-variable-value__container-label {
    display: flex;
    font-style: normal;
    font-family: "IBM Plex Sans";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    white-space: pre-wrap;
}

.device-status-variable-value__container-value {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    fill: solid;
  }
  
.device-status-variable-value__container-unit {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    fill: solid;
    margin-left: 4px;
}
  