.backstage-simulation-settings {
    .backstage-simulation-settings__protocol-title {
    height: 40px;
}

    // .backstage-simulation-settings__protocol-select {
    //     overflow-y: auto;
    // }

    .backstage-simulation-settings__simulation-devices {
    background: var(--color-gray-300);
        overflow-y: auto;
    }
}
