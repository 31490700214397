/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@import '../../../assets/styles/variables';

$base-size: 336px;
@function relsize($base-font-size, $height) {
    @return ($base-font-size / $base-size) * $height;
}

@mixin devicecard-device-body($height) {
    height: 100%;
    width: 100%;
    font-size: relsize(13px, $height);
    font-style: normal;
    font-weight: 400;
    line-height: relsize(17px, $height);
    letter-spacing: 0em;
    padding: relsize(8px, $height);
    overflow: hidden;
    grid-row: 2 / span 2;
    grid-column: 1 / span 1;
}

// $text-area-height: $height * 0.3825;

@mixin deviceCard($height)  {
    font-family: 'IBM Plex Sans';
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    width: 282px;
    max-width: 282px;
    height: $height;
    overflow: hidden;
    display: grid;
    text-align: left;
    background-color: var(--white);
    grid-template-columns: 1fr;
    grid-template-rows: auto $height * 0.4554;
    grid-template-areas:
    "image"
    "decription";

    &:hover {
        filter: brightness(0.90);
    }

    .devicecard-scan-spinner-container {
        grid-area: decription;
        position: relative;
        height: $height - $height * 0.4554;
        width: $height * 0.706;
        max-width: $height * 0.706;
        left: 0px;
        top: 0px;
        .devicecard-scan-spinner-indicator {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 8px;
        }
    }

    .devicecard-spinner-container {
        grid-area: image;
        position: relative;
        height: $height - $height * 0.4554;
        width: $height * 0.706;
        width: 100%;
        // max-width: $height * 0.706;
        left: 0px;
        top: 0px;
        .devicecard-spinner-indicator {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.8;
        }
    }

    .devicecard-text-container {
        grid-area: image;
        position: relative;
        height: $height - $height * 0.4554;
        width: $height * 0.706;
        width: 100%;
        // max-width: $height * 0.706;
        left: 0px;
        top: 0px;
        .devicecard-text-container__text {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1.0;
            font-weight: 700;
            color: var(--corporate-blue);
        }
    }

    .devicecard-image-container {
        grid-area: image;
        position: relative;
        left: 0px;
        top: 0px;
        display: flex;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        justify-content: center;
        .devicecard-image--not-supported {
            opacity: 0.5;
        }
        .devicecard-image {
            height: $height - $height * 0.4554;
        }
        .devicecard-nodevice-image {
            padding: 8px;
            height: $height - $height * 0.4554;
        }
      }

    .devicecard-device-body {
        grid-area: decription;
        @include devicecard-device-body($height);
        color: var(--white);
        background-color: var(--corporate-blue);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .devicecard-device-title {
            font-weight: 600;
            font-size: relsize(16px, $height);
            line-height: relsize(21px, $height);
          }

        .devicecard-device-text {
            padding-top: relsize(13px, $height);
            text-align: left;
        }

        .bmk-text {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .serial-text {
            text-align: left;
            width: 85%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .devicecard-nodevice-body {
        @include devicecard-device-body($height);
        color: #212022;
        background: var(--color-gray-300);
    }

    .devicecard-symbol-container {
        position: absolute;
        right: 8px;
        bottom: 8px;
        .devicecard-symbol {
            height: relsize(48px, $height);
            width: relsize(48px, $height);
        }
        .devicecard-radiobutton {
            background-color: var(--white);
            border: relsize(4px, $height) solid var(--gray);
            border-radius: 50%;
            width: relsize(32px, $height);
            height: relsize(32px, $height);
            .selected {
                background-color: var(--corporate-blue);
                border: 0;
                border-radius: 50%;
                width: relsize(14px, $height);
                height: relsize(14px, $height);
                margin: relsize(5px, $height);
            }
        }
    }
}

.devicecard-button-main {
    max-height: 336px;
    background-color: var(--white);
    .devicecard-device-container {
        @include deviceCard(336px);
    }
}

.devicecard-button-wizard {
    background-color: var(--white);
    max-height: $wizard-device-card-height;
    .devicecard-device-container {
        @include deviceCard($wizard-device-card-height);
    }
}

.no-devicecard-button-main {
    max-height: 336px;
    background-color: var(--white);
    .devicecard-device-container {
        cursor: default;
        @include deviceCard(336px);
    }
}

.devicecard-button-wizard:not(.selected) .devicecard-device-body {
    background-color: rgba(0, 152, 161, 0.5);
    filter: grayscale(0%);
}

.devicecard-device-root.disabled .devicecard-image-container {
    filter: grayscale(100%);
}

.devicecard-device-root.disabled .devicecard-device-body {
    filter: grayscale(100%);
    background-color: var(--corporate-blue);
}

// .devicecard-device-root.btn {
// .no-devicecard-button-main.btn {
.btn .devicecard-device-container {
    // override "uppercase" by ".btn" in "base.css"
    text-transform: none;
}

.devicecard-device-root.disabled {
    pointer-events: all;
    // cursor: pointer;

    // pointer-events: none; // disables mouse pointer hover effects
    // cursor: not-allowed; // <-- default for ".btn.disabled"
}
