.contactron-commissioning-wizard {
    .page-device-naming-image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .page-device-naming-image__image {
            width: 480px;
            height: 480px;
        }
    }
}