.contactron-motorstarter-cockpit {
    display: grid;
    grid-template-rows: 40% 60%;
    grid-template-columns: 30% 40% 30%;
    grid-template-areas: 
    "systemstatus systemdata workdata"
    "trend trend trend";
    width: 100%;
    height: 100%;
}

.contactron-motorstarter-cockpit-item  {
    padding: 4px;
}

.contactron-motorstarter-cockpit__systemstatus {
    grid-area: systemstatus;
}

.contactron-motorstarter-cockpit__systemdata {
    grid-area: systemdata;
}

.contactron-motorstarter-cockpit__operation {
    grid-area: workdata;
}

.contactron-motorstarter-cockpit__trend {
    grid-area: trend;
}