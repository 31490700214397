@mixin module-layout-row($count) {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat($count, 1fr);
    grid-template-rows: 1fr;
    gap: 8px;
}

@mixin contactron-station-cockpit-layout($row-count) {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat($row-count, 1fr);
    gap: 8px;

    @for $i from 4 through 8 {
        .layout-container-row--#{$i} {
            @include module-layout-row($i);
        }
    }

    .layout-container-row-card {
        width: 100%;
        height: 100%;
    }
}

