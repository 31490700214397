/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
 .deviceviewcontainer-container {
  display: grid;
  height: 100%;
  width: 100%;
  margin: 0px;
  background-color: var(--lighter-gray);
  grid-template-columns: max-content auto;
  grid-template-rows: 100%;
  grid-template-areas:
  "side main";
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.deviceviewcontainer-container__sidebar {
  grid-area: side;
}

.deviceviewcontainer-container__content {
  grid-area: main;
  height: 100%;
  padding: 4px;
}

