.iolink-system-command {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    z-index: 20000;
    padding: 0px;
    width: 100%;
    height: 100%;
}

.iolink-system-command__container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 64px auto 64px;
    grid-template-areas:
    "header"
    "body"
    "footer";
    padding: 16px 40px 16px 40px;
    width: 606px;
    height: 372px;
    border-radius: 8px;
    background-color: var(--white);
}

.iolink-system-command__container__header {
    display: flex;
    justify-content: center;
    grid-area: header;
}

.iolink-system-command__container__header__text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: var(--dark-gray);
}

.iolink-system-command__container__body {
    grid-area: body;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
}

.iolink-system-command__container__body__message {
    font-size: var(--font-size-lg);
    font-weight: 400;
    width: 100%;
    overflow-wrap: break-word;
}

.iolink-system-command__container__footer {
    grid-area: footer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iolink-system-command__container__footer__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
