.caparoc-channel-current-editor {
    display: grid;
    grid-template-columns: auto 320px 64px 64px;
    grid-template-rows: 1fr;
    grid-template-areas: 
    "enum chart value switch";
    // display: flex;
    // flex-direction: row;
    // height: 100%;
    width: 100%;
    height: 64px;

    .caparoc-channel-current-editor__enumerator {
        grid-area: enum;
        padding-top: 12px;
        padding-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;        
    }

    .caparoc-channel-current-editor__current-chart {
        grid-area: chart;
        display: flex;
        justify-items: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .caparoc-channel-current-editor__current-value {
        grid-area: value;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .caparoc-channel-current-editor__rotary-switch {
        grid-area: switch;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
        width: 100%;
        height: 100%;
    }
}

