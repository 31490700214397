/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

 .dip-switch-block-container {
  display: flex;
  column-gap: 16px;
}

.dip-switch-block-container__status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.dip-switch-block-container__status__on {
  margin-bottom: 10px;
}

.dip-switch-block-container__status__off {
  margin-bottom: 12px;
}

.dip-switch-block-container__switches {
  display: flex;
  column-gap: 4px;
}