.caparoc-channel__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.caparoc-channel__title {
  font-weight: bold;
  font-size: 17px;
}

.caparoc-channel__led-off {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: var(--white);
  box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.caparoc-channel__color-gray {
  background-color: var(--gray);
}

.caparoc-channel__color-green {
  background-color: var(--medium-green);
}

.caparoc-channel__color-red {
  background-color: var(--red);
}

.caparoc-channel__color-red-blink {
  background-color: var(--red);
  -webkit-animation: blinkGreyToRed 0.7s infinite;
  animation: blinkGreyToRed 0.7s infinite;
}

.caparoc-channel__color-yellow {
  background-color: var(--yellow);
}

.caparoc-channel__led-2 {
  -webkit-animation: blinkWhiteToGreen 0.7s infinite;
  animation: blinkWhiteToGreen 0.7s infinite;
}

@-webkit-keyframes blinkGreyToRed { 
  0% { background: var(--gray); }
  50% { background: var(--gray); }
  100% { background: var(--red); }
}
@keyframes blinkGreyToRed { 
  0% { background: var(--gray); }
  50% { background: var(--gray); }
  100% { background: var(--red); }
}
@-webkit-keyframes blinkWhiteToGreen { 
  0% { background: var(--white); }
  50% { background: var(--white); }
  100% { background: var(--medium-green); }
}
@keyframes blinkWhiteToGreen {
  0% { background: var(--white); }
  50% { background: var(--white); }
  100% { background: var(--medium-green); }
}

.caparoc-channel__led-3 {
  background-color: var(--yellow);
}

.caparoc-channel__led-4 {
  -webkit-animation: blinkWhiteToYellow 0.7s infinite;
  animation: blinkWhiteToYellow 0.7s infinite;
}

@-webkit-keyframes blinkWhiteToYellow { 
  0% { background: var(--white); }
  50% { background: var(--white); }
  100% { background: var(--yellow); }
}
@keyframes blinkWhiteToYellow {
  0% { background: var(--white); }
  50% { background: var(--white); }
  100% { background: var(--yellow); }
}

.caparoc-channel__led-5 {
  background-color: var(--red);
}

.caparoc-channel__led-6 {
  -webkit-animation: blinkWhiteToRed 0.7s infinite;
  animation: blinkWhiteToRed 0.7s infinite;
}

@-webkit-keyframes blinkWhiteToRed { 
  0% { background: var(--white); }
  50% { background: var(--white); }
  100% { background: var(--red); }
}
@keyframes blinkWhiteToRed {
  0% { background: var(--white); }
  50% { background: var(--white); }
  100% { background: var(--red); }
}

.caparoc-channel__led-7 {
  -webkit-animation: blinkRedToGreen 0.7s infinite;
  animation: blinkRedToGreen 0.7s infinite;
}

@-webkit-keyframes blinkRedToGreen { 
  0% { background: var(--red); }
  50% { background: var(--red); }
  100% { background: var(--medium-green); }
}
@keyframes blinkRedToGreen {
  0% { background: var(--red); }
  50% { background: var(--red); }
  100% { background: var(--medium-green); }
}

.caparoc-channel__led-8 {
  -webkit-animation: blinkRedToYellow 0.7s infinite;
  animation: blinkRedToYellow 0.7s infinite;
}

@-webkit-keyframes blinkRedToYellow { 
  0% { background: var(--red); }
  50% { background: var(--red); }
  100% { background: var(--yellow); }
}
@keyframes blinkRedToYellow {
  0% { background: var(--red); }
  50% { background: var(--red); }
  100% { background: var(--yellow); }
}

.caparoc-channel__nominal-current-label {
  font-size: 13px;
}

.caparoc-channel__nominal-current-value {
  font-size: 13px;
}

.caparoc-channel__output-current-label {
  margin-top: 10px;
  font-size: 13px;
}

.caparoc-channel__output-current__container {
  display: flex;
  flex-direction: row;
  line-height: 20px;
}

.caparoc-channel__output-current__container-label {
  font-size: 13px;
}

.caparoc-channel__output-current__container-value {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  margin-left: 4px;
}

.caparoc-channel__hidden {
  visibility: hidden;
}

.caparoc-channel__led-inactive {
  background-color: var(--dim-gray);
}

.caparoc-channel__text-inactive {
  color: var(--dim-gray);
}