/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
 :root {
  --device-catalog-card-height: 76px;
}

.device-catalog-card {
  height: 76px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  background-color: var(--white);
  text-align: left;
  color: var(--black);
}

.device-catalog-card:hover {
  background-color: #F2F2F2;
}

.device-catalog-card__image {
  overflow: hidden;
  margin-left: 8px;
  margin-right: 16px;
}

.device-catalog-card__image img {
  object-fit: contain;
  width: var(--device-catalog-card-height);
  width: 60px;
  height: 60px;
}

.device-catalog-card__description {
  height: 60px;
}

.device-catalog-card__description__text {
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.device-catalog-card__description__title {
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
}
