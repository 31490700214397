/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

 .preview-device-parameters-control {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px auto;
    grid-template-areas:
    "header"
    "context";
    box-shadow: 0px 1px 6px rgba(20, 11, 11, 0.25);
  }
  
  .preview-device-parameters-control__caption {
    grid-area: header;
    background: var(--light-gray);
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: 23px;
    padding: 6px 8px;
    overflow: hidden;
  }
  
  .preview-device-parameters-control__list {
    grid-area: context;
    background-color: var(--white);
    overflow: auto;
  }
  
  .preview-device-parameters-control__list .page-group {
    margin-top: 0;
  }
  
  .preview-device-parameters-control__list .list-group-item {
    padding: 0;
  }
  