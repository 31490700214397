/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@import '../../../assets/styles/variables';

.reset-wizard-page2-container {
  height: 100%;
  width: 100%;
}
