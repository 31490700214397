/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

@mixin button-font-base {
  font-size: 19.2px;
  font-weight: 600;
  line-height: 1.27;
}

@mixin button-size {
  width: 225px;
  height: 100%;
}

.button-text {
  @include button-font-base();
  @include button-size();
}

.button-container {
  margin: 0px 8px;
}