.dynfloatled-root {
    padding: 8px;
}

.dynfloatled-root__title {
    width: 100%;
    font-size: var(-font-size-xl);
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    text-align: end;
    vertical-align: middle;
    padding: 8px 32px 8px 8px;
    margin: 0;
}

.dynfloatled-root__led-container {
    width: 72px;
}

.dynfloatled {
    width: 60%;
    padding-top: 60%;
    border-radius: 50%;
}

.dynfloatled-blink-1-4Hz {
    -webkit-animation: blink 0.7s step-end infinite;
            animation: blink 0.7s step-end infinite;
}

.dynfloatled-blink-2-8Hz {
    -webkit-animation: blink 0.35s step-end infinite;
            animation: blink 0.35s step-end infinite;
}

@-webkit-keyframes blink { 50% { visibility: hidden; }}
        @keyframes blink { 50% { visibility: hidden; }}

.dynfloatled-red {
    background-color: var(--red);
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.dynfloatled-yellow {
    background-color: #FFDF0D;
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.dynfloatled-orange {
    background-color: var(--yellow);
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.dynfloatled-green {
    background-color: var(--medium-green);
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.dynfloatled-gray {
    background-color: var(--dim-gray);
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.dynfloatled-white {
    background-color: var(--white);
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}

.dynfloatled-black {
    background-color: var(--black);
    box-shadow: #fff 0 -1px 7px 1px, var(--dim-gray) 0 2px 12px;
}
