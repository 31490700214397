/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

$wizard-device-card-height: 330px;
$wizard-pc-device-max-height: 250px;
$wizard-progress-line-height: 8px;
