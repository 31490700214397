.chart-card-header-button__image_container__image {
  width: 24px;
  height: 24px;
}
  
.chart-card-header-button__image_container__image:focus-visible {
    outline-color: var(--button-border) !important;
  }
  
.chart-card-header-button__overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
    