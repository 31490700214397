.h5motorstarter-online-manual {
    overflow: hidden;

    .h5motorstarter-online-manual__title {
        overflow: hidden;
    }

    .h5motorstarter-online-manual__buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
