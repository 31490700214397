.change-device-page-2-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 80px 80px 80px;
  height: 100%;
  width: 100%;
}

.change-device-page-2-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 100%;
  height: 100%;
}

.change-device-page-2-status {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;
  font-weight: 400;
}

.change-device-page-2-task {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  font-size: 1.5rem;
  font-weight: 400;
}

.deviceSpinner {
  color: var(--dark-blue);
}
