/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/*
=== PXC CRISP STYLE DEFINITIONS AND VARIATIONS IN THIS APPLICATION ===

The styles and theming of this application "clipx ENGINEER devicetool"
is based on Phoenix Contact "Crisp style guide":
  https://www.phoenixcontact.com/common/index.html
"Crisp" is a set of web UI components and style definitions (SCSS)
which are compiled and minimized to the CSS file named "base.css":
  https://www.phoenixcontact.com/common/assets/stylesheets/base.css
The "base.css" file is included in this application's sources, it's
located in the same directory as this "application.css" file.

The style guidelines for this application differ from Crisp standards
at some points. This is handled by overriding according Crisp style
definitions by redefining them in this file.
The original Crisp style definitions in "base.css" are kept unmodified.
*/

:root {
  --changed-values: rgba(244, 183, 102, 0.5);
  --pri-button-fg: #F2F4F6;
  --pri-button-bg: #007C84;
  --sec-button-fg: #007C84;
  --sec-button-bg: #F2F4F6;
  --ter-button-fg: #007C84;
  --ter-button-bg: white;
  --mod-button-fg: #007C84;
  --mod-button-bg: var(--changed-values);
  --inv-button-fg: #007C84;
  --inv-button-bg: rgba(255, 11, 11, 0.5);
  --button-border: #e0e6e9;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
}

/* Overwrite Bootstrap Crisp Design with Figma Design */
.btn-primary {
  color: var(--pri-button-fg) !important;
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
}

.btn-primary:hover {
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-primary.focus,
.btn-primary:focus {
  -webkit-box-shadow:0 0 0 .2rem rgba(38,167,175,.5) !important;
  box-shadow:0 0 0 .2rem rgba(38,167,175,.5) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 2px 4px rgba(32, 32, 32, 0.6) !important;
  border-radius: 4px !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow:0 0 0 .2rem rgba(38,167,175,.5);
  box-shadow:0 0 0 .2rem rgba(38,167,175,.5)
}

.btn-primary-small {
  color: var(--pri-button-fg) !important;
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
  font-size: 1.0rem;
  font-weight: 600;
  line-height: 1.25;
  padding: 8px 24px;
}

.btn-primary-small:hover {
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-primary-small.focus,
.btn-primary-small:focus {
  -webkit-box-shadow:0 0 0 .2rem rgba(38,167,175,.5) !important;
  box-shadow:0 0 0 .2rem rgba(38,167,175,.5) !important;
}

.btn-primary-small.disabled,
.btn-primary-small:disabled {
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-primary-small:not(:disabled):not(.disabled).active,
.btn-primary-small:not(:disabled):not(.disabled):active,
.show > .btn-primary-small.dropdown-toggle {
  background: var(--pri-button-bg) !important;
  box-shadow: 0px 2px 4px rgba(32, 32, 32, 0.6) !important;
  border-radius: 4px !important;
}

.btn-primary-small:not(:disabled):not(.disabled).active:focus,
.btn-primary-small:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary-small.dropdown-toggle:focus {
  -webkit-box-shadow:0 0 0 .2rem rgba(38,167,175,.5);
  box-shadow:0 0 0 .2rem rgba(38,167,175,.5)
}

.btn-secondary {
  color: var(--sec-button-fg);
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
  border-radius: 4px !important;
}

.btn-secondary:hover {
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: var(--sec-button-fg);
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ccd0d2!important;
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--sec-button-fg) !important;
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  color: var(--sec-button-fg) !important;
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

/* Create new tertiary bootstrap button */
.btn-tertiary {
  color: var(--ter-button-fg);
  background: var(--ter-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
  border-radius: 4px !important;
}

.btn-tertiary:hover {
  background: var(--ter-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-tertiary.focus,
.btn-tertiary:focus {
  color: var(--ter-button-fg);
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: #ccd0d2!important;
  background: var(--ter-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-tertiary:not(:disabled):not(.disabled).active,
.btn-tertiary:not(:disabled):not(.disabled):active,
.show > .btn-tertiary.dropdown-toggle {
  color: var(--ter-button-fg) !important;
  background: var(--ter-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  color: var(--ter-button-fg) !important;
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

/* SMALL SECONDARY BUTTON */
.btn-secondary-small {
  color: var(--sec-button-fg);
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
  border-radius: 4px !important;
  font-size: 1.0rem;
  font-weight: 600;
  line-height: 1.25;
  padding: 8px 8px;
}

.btn-secondary-small:hover {
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-secondary-small.focus,
.btn-secondary-small:focus {
  color: var(--sec-button-fg);
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

.btn-secondary-small.disabled,
.btn-secondary-small:disabled {
  color: #ccd0d2!important;
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-secondary-small:not(:disabled):not(.disabled).active,
.btn-secondary-small:not(:disabled):not(.disabled):active,
.show > .btn-secondary-small.dropdown-toggle {
  color: var(--sec-button-fg) !important;
  background: var(--sec-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-secondary-small:not(:disabled):not(.disabled).active:focus,
.btn-secondary-small:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary-small.dropdown-toggle:focus {
  color: var(--sec-button-fg) !important;
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}


/* Create new modified bootstrap button */
.btn-modified {
  color: var(--mod-button-fg);
  background: var(--mod-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
  border-radius: 4px !important;
}

.btn-modified:hover {
  background: var(--mod-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-modified.focus,
.btn-modified:focus {
  color: var(--mod-button-fg);
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

.btn-modified.disabled,
.btn-modified:disabled {
  color: #ccd0d2!important;
  background: var(--mod-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-modified:not(:disabled):not(.disabled).active,
.btn-modified:not(:disabled):not(.disabled):active,
.show > .btn-modified.dropdown-toggle {
  color: var(--mod-button-fg) !important;
  background: var(--mod-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-modified:not(:disabled):not(.disabled).active:focus,
.btn-modified:not(:disabled):not(.disabled):active:focus,
.show > .btn-modified.dropdown-toggle:focus {
  color: var(--mod-button-fg) !important;
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

/* Create new invalid bootstrap button */
.btn-invalid {
  color: var(--inv-button-fg);
  background: var(--inv-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.3) !important;
  border-radius: 4px !important;
}

.btn-invalid:hover {
  background: var(--inv-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-invalid.focus,
.btn-invalid:focus {
  color: var(--inv-button-fg);
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

.btn-invalid.disabled,
.btn-invalid:disabled {
  color: #ccd0d2!important;
  background: var(--inv-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.15) !important;
}

.btn-invalid:not(:disabled):not(.disabled).active,
.btn-invalid:not(:disabled):not(.disabled):active,
.show > .btn-invalid.dropdown-toggle {
  color: var(--inv-button-fg) !important;
  background: var(--inv-button-bg) !important;
  border: 1px solid #A2A7A9 !important;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.15) !important;
  border-radius: 4px !important;
}

.btn-invalid:not(:disabled):not(.disabled).active:focus,
.btn-invalid:not(:disabled):not(.disabled):active:focus,
.show > .btn-invalid.dropdown-toggle:focus {
  color: var(--inv-button-fg) !important;
  -webkit-box-shadow:0 0 0 .2rem rgba(132,166,16,.5);
  box-shadow:0 0 0 .2rem rgba(132,166,16,.5)
}

.input-field,
.textfield__input {
  height: 35px;
}

.form__select,
.select-field {
  height: 35px;
}

.textfield__input {
  padding: 0 8px;
  width: 100%;
}

.select-icon {
  bottom: 8px;
}

.form__select {
  text-overflow: ellipsis;
  padding: unset;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(162, 167, 169, 0.7);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(162, 167, 169, 0.9);
}

/* ::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

::-webkit-scrollbar-track-piece:end {
   background: transparent;
} */

.preview-control--frame-padding {
  overflow: auto;
  padding-bottom: 36px;
}

.menu-control--bottom-padding {
  width: 100%;
  min-height: 16px;
}

.input-field-control__warning {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 16px;
}

.input-field-control__warning-text {
  padding-top: 1px;
  font-size: 9px;
  color: #f51423;
}