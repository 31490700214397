.trend-control {
  height: 100%;
  display: grid;
  grid-template-rows: 67px auto 62px;
}

.trend-control-header {
  grid-area: 1 / 1 / 1 / 1;
  display: flex;
  height: 35px;
}
  
.trend-control-dropdown {
  margin-left: 80px;
}

.playpausebutton {
  margin-left: 20px;
}

.chart {
  grid-area: 2 / 1 / 3 / 1;
  margin-top: -30px;
}

.hint {
  grid-area: 3 / 1 / 4 / 1;
  justify-self: center;
  font-size: 14px;
  line-height: 30px;
  margin: 16px 0px;
}

.info-icon {
  margin: 0px 8px 2px 0px;
}