.app-splash-screen {
  position: relative;
  color: var(--black);
}

.app-splash-screen__version {
  position: absolute;
  left: 603px;
  z-index: 1000;
  width: 50%;
  font-family: 'Gill Sans MT';
}

.app-splash-screen__version-norm {
  top: 224px;
}

.app-splash-screen__version-diff {
  top: 260px;
}

.app-splash-screen__copyright {
  position: absolute;
  left: 602px;
  z-index: 1000;
  width: 50%;
}

.app-splash-screen__copyright-norm {
  top: 302px;
}

.app-splash-screen__copyright-diff {
  top: 327px;
}

.app-splash-screen__splash-spinner {
  position: absolute;
  top: 150px;
  left: 900px;
  z-index: 1000;
}
