/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.create-backup-component {
  height: 100%;
  width: 100%;
}

.create-backup-component__title {
  font-weight: bold;
  font-size: var(--font-size-xl);
}

.create-backup-component__message {
  padding: 1em 0;
  width: 100%;
}

.create-backup-component__description_title {
  font-size: 125%;
}

.create-backup-component__description_input textarea {
  height: 10em;
  resize: none;
}

.create-backup-component__buttons {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
}

// .create-backup-component__buttons > * {
//   margin-top: 2em;
// }

// .create-backup-component__button_print_parameterset Button {
//   margin-right: .5em;
// }

// .create-backup-component__button_create_backup_file Button {
//   margin-left: .5em;
// }
