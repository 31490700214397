.device-value-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.device-value-picker-layout__control--x-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.device-value-picker__button {
    padding: 0px 8px !important;
    height: 36px;
    width: 36px;
    margin: 0px 2px;
    margin-bottom: 2px;
}

.device-value-picker__button__arrow {
    color: var(--corporate-blue);
    padding: 0;
    margin: 0;
}

.device-value-picker-unit {
    display: flex;
    width: auto;
    text-align: end;
    white-space: nowrap;
    overflow: visible;
  }
  
  .device-value-picker-unit p {
    font-size: var(--font-size-lg);
  }