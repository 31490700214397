.layout-card-action-submenu__element {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px 12px;
}

.layout-card-action-submenu__element-button {
    width: fit-content;
}

.layout-card-action-submenu__element-icon {
    width: 16px;
    height: 16px;
}

.layout-card-action-submenu__element-text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-left: 8px;
    color: #00838A;
}
