.contactron-commissioning-wizard {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

        .page-station-group-process-data__table__splitter {
            padding: 8px 0px;       
        }
    
        .page-group-process-data-table {
            width: 100%;

            .page-group-process-data-table-header {
                background-color: #E1E5E9;
            }
       
            tr {
                border: 1px solid #E1E5E9;
                height: 40px;
            }

            td {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }

            
            tbody td:first-child,
            thead th:first-child {
                padding-left: 8px;
            }
        }

        .page-group-process-data-table-title {
            padding-bottom: 16px;       
        }

        .page-group-process-data-table-title2 {
            padding: 16px 0px;       
        }

        .page-group-process-data-table-title-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
}
