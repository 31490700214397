$block-padding: 24px;

.online-caparoc-tab-control {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    "editor image";

    .online-caparoc-tab-control__editor {
        height: 100%;
        width: 100%;
        padding: 36px;
        grid-area: editor;

        .online-caparoc-tab-control__editor__switch {
            width: 100%;

            .online-caparoc-tab-control__editor__switch__title {
                padding-bottom: 12px;
                font-size: var(--font-size-lg);
                font-weight: 700;
            }

            .online-caparoc-tab-control__editor__switch__controls {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .online-caparoc-tab-control__editor__switch__controls__switches {
                    display: flex;
                    flex-direction: row;
                }

                .online-caparoc-tab-control__editor__switch__controls__text {
                    margin-top: 16px;
                    padding-left: 36px;
                    width: 100%;
                    overflow: hidden;
                }
            }
        }
        
        .online-caparoc-tab-control__editor__nominal_current {
            padding-top: $block-padding;

            .online-caparoc-tab-control__editor__nominal_current__title {
                padding-bottom: 24px;
                font-size: var(--font-size-lg);
                font-weight: 700;
            }
        }

        .online-caparoc-tab-control__editor__error_counter {
            padding-top: $block-padding;

            .online-caparoc-tab-control__editor__error_counter__title {
                padding-bottom: 24px;
                font-size: var(--font-size-lg);
                font-weight: 700;
            }
        }
    }
        
    .online-caparoc-tab-control__image {
        grid-area: image;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .online-caparoc-tab-control__image__image {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 72px;
        }
    }
}
