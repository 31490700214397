.force-analog-output-control {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 16px 1fr;
    grid-template-rows: 36px 16px 48px auto;
    gap: 8px;
    grid-template-areas: 
        "title title title"
        "text text text"
        "absolute . percentage"
        "input . button";

    .force-analog-output-control__title {
        grid-area: title;
        font-weight: 600;
        font-size: 24px;
        overflow: hidden;
    }

    .force-analog-output-control__text {
        grid-area: text;
        font-weight: 400;
        font-size: 16px;
        overflow: hidden;
    }

    .force-analog-output-control__absolute_checkbox {
        grid-area: absolute;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        overflow: hidden;
    }

    .force-analog-output-control__percentage_checkbox {
        grid-area: percentage;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        overflow: hidden;
    }

    .force-analog-output-control__input {
        grid-area: input;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        overflow: hidden;
        padding: 0 8px 0 0;
    }

    .force-analog-output-control__button {
        grid-area: button;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        overflow: hidden;
    }
}