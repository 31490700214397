.FigmaGaugeControlChart-Gauge {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  max-width: 320px;
  overflow: hidden;
}

.FigmaGaugeControlChart-Gauge__segmentsContainer {
  width: 80%;
  max-width: 232px;
  height: fit-content;
}

.FigmaGaugeControlChart-Gauge__indicatorContainer {
  width: 100%;
  position: relative;
}

.FigmaGaugeControlChart-Gauge__needleContainer {
  width: 100%;
  position: relative;
}

.FigmaGaugeControlChart-Gauge__segmentsContainer-segment {
  width: 100%;
  height: 0px;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  transform-origin: center top;
}

.FigmaGaugeControlChart-Gauge__segmentsContainer-segmentCover {
  width: 80%;
  height: 160%;
  background: var(--white);
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.FigmaGaugeControlChart-Gauge__titleContainer {
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.FigmaGaugeControlChart-Gauge__titleContainer-overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.FigmaGaugeControlChart-Gauge__titleContainer-title {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  color: var(--black);
  text-align: center;
  padding-bottom: 0px;
}

.FigmaGaugeControlChart-Gauge__Gauge__titleContainer-subtitle {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  color: #666667;
  text-align: center;
}

.FigmaGaugeControlChart-Gauge__Gauge__titleContainer-image {
  position: absolute;
  top: 0%;
  left: 108%;
}

.FigmaGaugeControlChart-Gauge__Gauge__titleContainer-image:focus-visible {
  outline-color: var(--button-border) !important;
}

.FigmaGaugeControlChart-Gauge__extremeIndicatorContainer {
  width: 100%;
  height: 0px;
  background: var(--white);
  transform-origin: center top;
}

.FigmaGaugeControlChart-Gauge__arrowContainer {
  width: 100%;
  height: 0px;
  background: #050505;
  transform-origin: center top;
  position: relative;
}

.FigmaGaugeControlChart-Gauge__labelContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 0px;
  background: var(--white);
  transform-origin: center top;
}

.FigmaGaugeControlChart-Gauge__extremeIndicatorContainer-lineIndicator {
  width: 11%;
  background: var(--black);
}

.FigmaGaugeControlChart-Gauge__arrowContainer-arrow {
  position: absolute;
  left: 15%;
}

.FigmaGaugeControlChart-Gauge__labelContainer-label {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0%;
  left: 100%;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0em;
  text-align: start;
  color: #5C5C5C;
}

.hideGaugeSegment {
  display: none;
}

.FigmaGaugeControlChart-Gauge__currentValueContainer {
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
}

.FigmaGaugeControlChart-Gauge__currentValueContainer-valueText {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.FigmaGaugeControlChart-Gauge__currentValueContainer-unitText {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  color: #333333;
  text-align: center;
}
.FigmaGaugeControlChart-Gauge__container-ExtremesContainer {
  display: flex;
  justify-content: center;
  height: 50px;
}

.FigmaGaugeControlChart-Gauge__ExtremesContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.FigmaGaugeControlChart-Gauge__currentValueContainer-extremeValueText {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  color: var(--gray);
  text-align: center;
}

.FigmaGaugeControlChart-Gauge-Segment-LowLow, .FigmaGaugeControlChart-Gauge-Segment-HighHigh{
  background-color: var(--red);
}

.FigmaGaugeControlChart-Gauge-Segment-Low, .FigmaGaugeControlChart-Gauge-Segment-High{
  background-color: var(--yellow);
}

.FigmaGaugeControlChart-Gauge-Segment-Normal{
  background-color: var(--medium-green);
}

.FigmaGaugeControlChart-Gauge-Segment-WhiteCover{
  background-color: var(--white);
}

.roundEdge-positionLeft {
  position: absolute;
  top: 100%;
  left: 0;
}

.roundEdge-positionRight {
  position: absolute;
  top: 100%;
  left: 90%;
}

.edge-Container {
  position: relative;
  width: 100%;
  height: 0px;
}