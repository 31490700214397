/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.nav-item {
    color: var(--black);
}

.nav-tabs .nav-link {
    padding: 16px 40px;
    font-size: var(--font-size-xl);
    font-weight: 600;
    text-transform: uppercase;
    overflow: hidden;
}

/* .tab-content {
    background-color: var(--white);
} */

.nav-link:hover {
    color: var(--corporate-blue);
}

.nav-tabs .nav-link.active {
    color: var(--corporate-blue);
    background-color: var(--white);
}

.nav-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.nav-tabs--normal {
    background-color: var(--light-gray);
}

.nav-tabs--modified {
    background-color: var(--changed-values);
}

.nav-tabs--error {
    background-color: rgba(255, 11, 11, 0.5);
}

// .menu-tabs {
//     height: 100%;
// }

.menu-tabs-content--overflow-auto {
    overflow: auto;
}
