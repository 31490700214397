$block-padding: 24px;

.online-caparoc-module {
    height: 100%;
    width: 100%;

    .tab-content {
        height: calc(100% - 54px);

        .tab-pane {
            height: 100%;
        }
    }

    .online-caparoc-module__tab-control {
        height: 100%;
        width: 100%;
    }
}
