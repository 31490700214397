/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
 .mac-address-container__input {
    width: 100%;
}

.mac-address-container__input__inputbox {
    flex-grow: 10;
}

.mac-address-container__delimeter {
    padding: 0 4px 0 4px;
    font-weight: 600;
}
