.online-caparoc-station {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 16px;

    .tab-content {
        overflow: hidden;
        height: 100%;   
    }

    .online-caparoc-station__element {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
