/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.preview-dataset-redux-control {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto;
  grid-template-areas:
  "header"
  "context";
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  background-color: green;
}

.preview-dataset-redux-control__caption {
  grid-area: header;
  background: var(--light-gray);
  padding: 6px 8px;
}

.preview-dataset-redux-control__caption p {
  font-weight: 600;
  font-size: var(--font-size-lg);
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
}

.preview-dataset-redux-control__list {
  grid-area: context;
  background-color: var(--white);
  overflow: auto;
}

.preview-dataset-redux-control__list .page-group {
  margin-top: 0;
}

.preview-dataset-redux-control__list .list-group-item {
  padding: 0;
}

.preview-dataset-redux-control__list-group {
  overflow: auto;
}
