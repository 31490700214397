.select-process-checkbox {
    display: flex;
    column-gap: 8px;
    align-items: center;
    width: 100%;

    .select-process-checkbox-input {
        display: none;
    }
    
    .select-process-checkbox-svg {
        display: inline-block;
        height: 30px;
        width: 30px;
        background: #fff;
        border: 2px var(--corporate-blue) solid;
        margin-right: 4px;
    }

    .select-process-checkbox-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}
