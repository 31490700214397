.contactron-layout16-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.contactron-layout16-card__status {
    height: 6px;
    width: 100%;
    background-color: var(--medium-green);
}

.contactron-layout16-card__status-color-green {
    background-color: var(--medium-green);
}

.contactron-layout16-card__status-color-yellow {
  background-color: var(--yellow);
}

.contactron-layout16-card__status-color-red {
  background-color: var(--red);
}

.contactron-layout16-card__header {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #EAEAEA;
    padding: 8px;
    justify-content: space-between;
  }
  
  .contactron-layout16-card__header-title {
    color: #3a3a3a;
    font-weight: 400;
    font-size: 16px;
    font-family: "IBM Plex Sans";
    overflow: hidden;
  }

  .contactron-layout16-card__content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 8px;
  }

  .contactron-layout16-card__content-element {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
  }

  .contactron-layout16-card__content-element-text {
    font-style: normal;
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  .contactron-layout16-card__content-element-text-bold {
    font-style: normal;
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
