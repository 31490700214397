.warning-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    z-index: 20000;
    padding: 0px;
    width: 100%;
    height: 100%;

    .warning-dialog-container {
        width: 738px;
        height: 432px;
        border-radius: 1px;
        background-color: var(--white);
        display: grid;
        grid-template-columns: 60px auto;
        grid-template-areas: "color data";

        .warning-dialog-color {
            grid-area: color;
            border-top-left-radius: 1px;
            border-bottom-left-radius: 1px;
            background-color: #FFA800;
            display: flex;
            justify-content: center;
            margin: 8px 0px 8px 8px;

            .warning-dialog-color__image {
                padding-top: 16px;
            }
        }

        .warning-dialog-data {
            grid-area: data;
            display: grid;
            grid-template-rows: 64px auto 72px;
            grid-template-areas:
            "header"
            "message"
            "buttons";

            .warning-dialog-data__header {
                grid-area: header;
                display: flex;
                align-items: center;
                margin: 0px 16px;

                .warning-dialog-data__header__text {
                    font-family: "IBM Plex Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 48px;
                }
            }

            .warning-dialog-data__message {
                grid-area: message;
                display: flex;

                .warning-dialog-data__message-text {
                    padding: 16px 16px;

                    .warning-dialog-data__message-text__data {
                        font-family: "IBM Plex Sans";
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .warning-dialog-data__buttons {
                grid-area: buttons;
                padding: 0px 16px;

                .warning-dialog-data__buttons-container {
                    display: flex;
                    justify-content: space-between;
                    height: 56px;
                    width: 100%;

                    .warning-dialog-data__buttons-container__cancel {
                        width: 182px;
                    }

                    .warning-dialog-data__buttons-container__ok {
                        width: 172px;
                    }
                }
            }
        }
    }

}
