  .transfer-selector {
    display: grid;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 40px 112px auto 92px;
    grid-template-areas: 
    "title"
    "content"
    "content"
    "buttons";
    background-color: var(--white);
    padding: 0px 64px;
  
    .transfer-selector__content {
        position: relative;
        grid-area: content;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 16px;

        .transfer-selector__content__title {
            font-size: var(--font-size-xxxl);
            text-align: center;
        }

        .transfer-selector__content__image {
            width: 150px;
        }
    }
    // .transfer-selector__content__left {
    //     padding: 16px;
    // }
    // .transfer-selector__content__right {
    //     padding: 16px;
    // }
    .transfer-selector__title {
        grid-area: title;
        p {
            font-weight: 600;
            font-size: var(--font-size-xxxl);
            line-height: 35px;
            color: var(--black);
            padding: 8px 8px 0px 0px;
        }
    }
    .transfer-selector__buttons {
        grid-area: buttons;
        padding: 16px 0px 16px 0;
    }
}
  