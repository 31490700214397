/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

 .station-navigation__container {
  display: grid;
  height: 100%;
  grid-template-columns: 52px auto;
  grid-template-rows: 45% 55%;
  grid-template-areas:
  "icon title"
  "icon dropdown";
}

.station-navigation__icon {
  grid-area: icon;
  display: flex;
  align-self: center;
  justify-content: center;
}

.station-navigation__icon img {
  width: 24px;
}

.station-navigation__title {
  grid-area: title;
  align-self: end;
  color: var(--black);
  font-weight: 600;
  font-size: var(--font-size-lg);
  line-height: var(--line-height-base);
  padding-bottom: 5px;
}

.station-navigation__dropdown {
  grid-area: dropdown;
}

.station-navigation__container .station-navigation__dropdown .dropdown .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}