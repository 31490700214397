
.variable-value-table__col__label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    width: auto;

    .variable-value-table__col__label__text {
        font-size: 16px;
    }
}

.variable-value-table__col__delimiter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    width: auto;
    padding-right: 8px;
}

.variable-value-table__col__value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    width: auto;
    padding-bottom: 2px;
    
    .variable-value-table__col__value__text {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
    }
}

