.contactron-commissioning-wizard {
    .page-station-addressing-button {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .page-station-addressing-button__text {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 16px;
            font-size: 16px;
            font-weight: 400;
        }

        .page-station-addressing-button__button {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 8px;

            .btn-secondary-small {
                padding: 8px 24px;
            }
        }
    }
}