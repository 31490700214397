.Tooltip-control {
  width: 140px;
  position: absolute;
  background: var(--white);
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  padding: 8px 4px;
  border: 1px solid #E0DFE1;
  z-index: 9999;
  bottom: 100%;
  left: 50%;
  transform: translate(-70px, -6px) ;
}

/* .Tooltip-control::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #E0DFE1 transparent transparent transparent;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
} */

.Tooltip-control-item {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}