.caparoc-channel-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.caparoc-channel-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30px;
  height: 30px;
  background: var(--medium-gray);
  border-radius: 50%;
  position: relative;
  transition: background-color .2s;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgb(32 32 32 / 30%);
}

.caparoc-channel-switch-color--green {
  background: radial-gradient(var(--white), 10%, var(--green));
  box-shadow: 1px 1px 10px var(--green);
}

.caparoc-channel-switch-color--white {
  background: radial-gradient(var(--white), 10%, var(--white));
  border: 1px solid var(--medium-gray);
}

.caparoc-channel-switch-color--red {
  background: radial-gradient(var(--white), 10%, var(--red));
  box-shadow: 1px 1px 10px var(--red);
}

.caparoc-channel-switch-color--yellow {
  background: radial-gradient(var(--white), 10%, var(--yellow));
  box-shadow: 1px 1px 10px var(--yellow);
}

.caparoc-channel-switch-color--red-blink {
  background-color: var(--red);
  -webkit-animation: blinkWhiteToRed 0.7s infinite;
  animation: blinkWhiteToRed 0.7s infinite;
}

@-webkit-keyframes blinkWhiteToRed { 
  0% { 
    background: radial-gradient(var(--white), 10%, var(--white));
    border: 1px solid var(--medium-gray);
  }
  50% {
    background: radial-gradient(var(--white), 10%, var(--white));
    border: 1px solid var(--medium-gray);
  }
  100% { 
    background: radial-gradient(var(--white), 10%, var(--red));
    box-shadow: 1px 1px 10px var(--red);
    }
}
@keyframes blinkWhiteToRed { 
  0% { 
    background: radial-gradient(var(--white), 10%, var(--white));
    border: 1px solid var(--medium-gray);
  }
  50% {
    background: radial-gradient(var(--white), 10%, var(--white));
    border: 1px solid var(--medium-gray);
  }
  100% { 
    background: radial-gradient(var(--white), 10%, var(--red));
    box-shadow: 1px 1px 10px var(--red);
  }
}
