.op-device-status-control {
    display: flex;
    align-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 8px;
    width: 100%;

    .op-device-status-control__padding {
        width: 28px;
    }

    .op-device-status-control__label {
        display: flex;
        font-style: normal;
        font-family: "IBM Plex Sans";
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        white-space: pre-wrap;
    }
    
    .op-device-status-control__value {
        font-style: normal;
        font-family: "IBM Plex Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
    } 

    .op-device-status-control__unit {
        font-family: "IBM Plex Sans";
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        fill: solid;
        margin-left: 4px;
    }
}
