/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
@import 'ButtonList.Base.scss';

.print-button-grid {
  display: grid;
  grid-template-columns: 17px auto  17px;
  grid-template-rows: auto 7px 10px 7px auto;
}

.print-button-text {
  grid-column: 2 / 3;
  grid-row: 2 / 5;
}

.print-button-chevron {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}