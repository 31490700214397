.FigmaBargraphControlHorizontalChart-Bargraph{
  position: relative;
  width: 100%;
  max-width: 300px;
}

.FigmaBargraphControlHorizontalChart-Bargraph__TitleContainer {
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.FigmaBargraphControlHorizontalChart-Bargraph__title {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  color: var(--black);
  text-align: center;
  padding-bottom: 0px;
}

.FigmaBargraphControlHorizontalChart-Bargraph__subtitle {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  color: #666667;
  text-align: center;
}

.FigmaBargraphControlHorizontalChart-Bargraph__image {
  position: absolute;
  top: 0%;
  left: 108%;
}

.FigmaBargraphControlHorizontalChart-Bargraph__overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.FigmaBargraphControlHorizontalChart-Bargraph__image:focus-visible {
  outline-color: var(--button-border) !important;
}

.FigmaBargraphControlHorizontalChart-Bargraph__ControlContainer {
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.FigmaBargraphControlHorizontalChart-Bargraph__BarContainer {
  display: flex;
  width: 80%;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

.FigmaBargraphControlHorizontalChart-Bargraph__SegmentContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}

.FigmaBargraphControlHorizontalChart-Bargraph-ExtremeIndicatorContainer {
  width: 0px;
  height: fit-content;
  background: var(--white);
}

.FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-extremeIndicator {
  background: var(--black);
}

.FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-item {
  flex: 0 1 auto;
}

.FigmaBargraphControlHorizontal-Bargraph__Line {
  width: 100%;
  background-color: #666667;
}

.FigmaBargraphControlHorizontalChart-Bargraph__TickContainer {
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick {
  flex: 0 1 auto;
  background-color: #666667;
}

.FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item {
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 0px;
}

.FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label {
  flex: 0 1 auto;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: start;
  color: #666667;
}

.FigmaBargraphControlHorizontalChart-Bargraph__CurrentValueContainer {
  display: flex;
  height: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.FigmaBargraphControlHorizontalChart-Bargraph__CurrentValueContainer-valueText {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  color: #333333;
  text-align: center;
}

.FigmaBargraphControlHorizontalChart-Bargraph__CurrentValueContainer-unitText {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  color: #333333;
  text-align: center;
}

.FigmaBargraphControlHorizontalChart-Segment-LowLow, .FigmaBargraphControlHorizontalChart-Segment-HighHigh{
  background-color: var(--red);
}

.FigmaBargraphControlHorizontalChart-Segment-Low, .FigmaBargraphControlHorizontalChart-Segment-High{
  background-color: var(--yellow);
}

.FigmaBargraphControlHorizontalChart-Segment-Normal{
  background-color: var(--medium-green);
}

.hideBargraphSegment{
  display: none;
}

.FigmaBargraphControlHorizontalChart-Bargraph__ExtremesContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}

.FigmaBargraphControlHorizontalChart-Bargraph__ExtremesContainer-text {
  width: fit-content;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  color: var(--gray);
  text-align: center;
}