.MessageBoxContainer__Container {
    width: 100%;
    height: 100%;
    padding-top: 56px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 14px;
    overflow-y: scroll;
}
