.data-table-control-container {
  width: 100%;
  height: 100%;
  overflow: auto;

  .data-table-control {
    display: table;         
    width: 100%;
    table-layout: fixed;

    .data-table-control-row-header {
      display: table-row;
      width: 100%;
      height: auto;
      clear: both;
      overflow: hidden;
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
      // background-color: #E1E5E9;
      background-color: white;
    }

    .data-table-control-row {
      display: table-row;
      width: 100%;
      height: auto;
      clear: both;
      overflow: hidden;
    }

    .data-table-control-cell {
      float: left; /* fix for  buggy browsers */
      display: table-cell;         
      padding: 10px;
      overflow: hidden;
      width: 250px;
      height: 36px;
    }

    .data-table-control-cell--header {
      border-bottom: 1px solid #E1E5E9;
      &:not(:first-child) {
          border-left: 1px solid #E1E5E9;
      }
    }

    .data-table-control-cell--cell {
      &:not(:first-child) {
          border-left: 1px solid #E1E5E9;
      }
    }
  }
}
