.status-message-container {
  width: 100%;
  padding: 8px 8px 8px 8px;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
}

.status-message-container-text {
  font-family:  "IBM Plex Sans";
  font-weight: 600;
  font-style: normal;
  color: var(--black);
}

.status-message-green {
  border-color: var(--green);
}

.status-message-yellow {
  border-color: var(--yellow);
}

.status-message-red {
  border-color: var(--red);
}