.device-process-data-input-output {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: 100%;
    grid-template-areas:
      "input output";

    .device-process-data-input-output__input {
        grid-area: input;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 40px auto;
        grid-template-areas:
        "header" "data";

        .device-process-data-input-output__input-data {
            grid-area: data;
            display: grid;
            grid-template-rows: repeat(7, 1fr);
            grid-template-columns: repeat(3, 1fr);
            grid-auto-flow: column;
        }
    } 

    .device-process-data-input-output__output {
        grid-area: output;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 40px auto;
        grid-template-areas:
        "header" "data";

        .device-process-data-input-output__output-data {
            grid-area: data;
            display: grid;
            grid-template-rows: repeat(7, 2fr);
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: column;
        }
    }

    .device-process-data-input-output__input-header {
        grid-area: header;
        display: flex;
        align-items: center;
    }

    .device-process-data-input-output-container-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .device-process-data-input-output-header {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }
}
