.DeviceStatusControl_label {
  display: flex;
  font-style: normal;
  font-family: "IBM Plex Sans";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  white-space: pre-wrap;
}

.DeviceStatusControl_label_1 {
  font-style: normal;
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.DeviceStatusControl_icon {
  padding-right: 8px;
}

.DeviceStatusControl_question {
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 3px;
}

.DeviceStatusControl_content {
  display: flex;
  align-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-bottom: 8px;
  width: 100%;
}
