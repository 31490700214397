.zoom-control-container {
    display: flex;
    flex-direction: row;
    column-gap: 2px;
}

.zoom-control-container-textfield__input {
    height: 22px;
    width: 76px;
}

.zoom-control-container__button-container {
    width: 22px;
}

.zoom-control-container__button {
    padding: 0px 4px !important;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoom-control-container__button__icon {
    font-size: 30px;
    padding-bottom: 4px;
}