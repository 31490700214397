.lin-table-sign {
  position: absolute;
  left: 0;
  left: 50%;
  transform: translate(-50%, -0%);
}

.lin-table-elements {
    position: relative;
}
