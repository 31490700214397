.CurrentStatusControl__Container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding-left: 8px;
}

.CurrentStatusControl__TitleContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-evenly;
    width: 100%;
}

.CurrentStatusControl__TitleContainer-channel {
    font-family: "IBM Plex Sans";
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    fill: solid;
    vertical-align: top;
}

.CurrentStatusControl__TitleContainer-label {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    fill: solid;
    vertical-align: top;
}


.CurrentStatusControl__TitleContainer-valueUnit {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CurrentStatusControl__TitleContainer-value {
    font-family: "IBM Plex Sans";
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
    color: #333333;
    fill: solid;
    vertical-align: top;
}

.CurrentStatusControl__TitleContainer-unit {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    fill: solid;
    margin-left: 8px;
    margin-top: 2px;
}