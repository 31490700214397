.powerbar-chart {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: 
        "value"
        "value"
        "chart"
        ".";
    width: 100%;
    height: 100%;

    .powerbar-chart__value {
        grid-area: value;
        font-weight: 700;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .powerbar-chart__chart {
        grid-area: chart;
        width: 100%;
        display: flex;
        align-items: center;
    }
}
