.contactron-commissioning-wizard {
    width: 100%;
    height: 100%;
    
    .page-station-group-process-data {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 20% 60% 20%;
        grid-template-rows: 25% auto;
        grid-template-areas:
        ". header ."
        ". table .";

        .page-station-group-process-data__header {
            grid-area: header;
        }
        .page-station-group-process-data__table {
            grid-area: table;
            padding: 0px 16px 24px 0px;
        }
    }
}