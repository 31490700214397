.contactron-commissioning-wizard {
    .page-station-addressing-control {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 360px auto;
        grid-template-rows: 1fr;
        grid-template-areas: 
            "image text";

        .page-station-addressing-control__text {
            grid-area: text;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
            padding: 24px;
        }

        .page-station-addressing-control__text--hidden {
            visibility: hidden;
        }

        .page-station-addressing-control__image {
            grid-area: image;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            
            .page-station-addressing-control__image--image {
                width: 325px;
                height: 325px;
                overflow: hidden;
            }
        }
    }
}