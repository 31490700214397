/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.page-message-container__status__logo {
  margin-right: 20px;
}

.page-message-container__status__logo img {
  height: 75px;
}

.page-message-container__status__title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.page-message-container__status__text {
  font-size: medium;
}
