/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.helpbar-container {
  z-index: 1000;
  padding: 32px;
}

.helpbar-container__title p {
  font-size: var(--font-size-xxxl);
  font-weight: 400;
}

.helpbar-container__content {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40% 50% 10%;
  grid-template-areas:
  "menu-context"
  "param-context"
  "param-message";
}

.helpbar-container__content__menu-context {
  grid-area: menu-context;
  border-bottom: 1px solid var(--dim-gray);
  font-size: var(--font-size-base);
  line-height: 23px;
}

.helpbar-container__content__menu-context__text {
  height: calc(100% - 40px); // Height of Title
  font-size: inherit;
  overflow: auto;
}

.helpbar-container__content__param-context {
  grid-area: param-context;
  border-bottom: 1px solid var(--dim-gray);
}

.helpbar-container__content__param-context__text {
  height: calc(100% - 65px); // Height of Title
  overflow: auto;
}

.helpbar-container__content__param-message {
  grid-area: param-message;
  height: calc(100% - 30px); // Height of margins
  overflow: auto;
}

.helpbar-container__content__param-context__title p,
.helpbar-container__content__menu-context__title p {
  font-weight: 600;
  font-size: var(--font-size-xl);
  line-height: 23px;
}

.helpbar-container__content__param-context__warning p {
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 21px;
  font-size: var(--font-size-base);
  color: var(--red);
}

.helpbar-markdown {
  line-height: 21px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: var(--font-size-base);
}

.helpbar-markdown-warning {
  line-height: 21px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: var(--font-size-base);
  color: var(--red);
}
