.smallTableView-container {
    display: flex;
    flex-direction: column;
    height: 584px;
    width: 300px;
}

.smallTableView-container-top {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 25%;
    width: 100%;
}

.smallTableView-container-top-icon-container {
    position: absolute;
    left: 90%;
    top: 15%;
}

.smallTableView-container-top-icon-container:focus-visible {
    outline-color: var(--button-border) !important;
}

.smallTableView-container-top-expand-overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.smallTableView-container-top-icon-container-icon {
    width: 32px;
    height: 32px;
}

.smallTableView-container-top-expand-container {
    top: -8%;
    right: 11%;
    position: absolute;
    z-index: 9999;
    background: var(--white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    align-self: stretch;
    width: fit-content;
    padding: 4px;
}

.smallTableView-container-top-headeritem {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
    padding-top: 8px;
}

.smallTableView-container-top-headeritem-label {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--black);
    fill: solid;
    padding-right: 8px;
    overflow: hidden;
}

.smallTableView-container-top-headeritem-inputbox {
    width: 85px;
    min-width: 85px;
}
