.LinearizationPoint {
  background: var(--white);
  border: 5px solid var(--dark-gray);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.LinearizationPoint-tooltop-container {
  position: 'relative'; 
  width: '100%'; 
  height: '0px',
}

.LinearizationPoint:hover {
  background: var(--corporate-blue);
  border: 5px solid var(--white);
}

.LinearizationPoint-invalid {
  background: var(--white);
  border: 5px solid red;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
};
