/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

// .parameter-view {
//     width: 100%;
//     height: 100%;
// }

.parameter-view .tab-content {
    flex-grow: 1;
    overflow: auto;
    background-color: var(--white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
}

// .parameter-view__loading-spinner {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 100%;
// }