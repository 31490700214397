.cold-junction-line-cmp {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-left: 40px;
    padding-bottom: 40px;

    .cold-junction-line-cmp-text {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
}
