.password-input-field {
  width: 100%;
}

.password-input-field .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 9px;
}

.password-input-field .textfield__input--edited {
  background-color: var(--changed-values) !important;
}

.password-control {
  display: flex;
}

.password-control-icon > img {
  width: 16px;
  height: 16px;
  margin-top: 8px;
  margin-left: 8px;
}
