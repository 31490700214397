.dip-switch-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.dip-switch-container__header {
  width: 100%;
  margin: 0px 0px 5px 0px;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
}

.dip-switch-container__header--changed {
  background-color: var(--changed-values);
}

.dip-switch-container__header__label {
  display: inline-block;
}