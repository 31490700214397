/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.navbutton {
    height: 100px;
    width: 100%;
    padding-left: 15px;
    background-color: var(--white);
    cursor: pointer;
}

.navbutton:focus {
    outline: none;
}

.navbutton.selected {
    background-color: var(--lighter-gray);
}

.navbutton.disabled {
    cursor: not-allowed;
}

.navbutton-title-format {
    color: var(--black);
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: var(--line-height-base);
}

.navbutton-subtitle-format {
    color: var(--gray);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-base);
}