/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.wizard-view-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40px 112px auto 92px;
  grid-template-areas: 
  "title"
  "stepper"
  "content"
  "buttons";
  background-color: var(--white);
  padding: 0px 64px;
}
.wizard-view-container--no-stepper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40px 112px auto 92px;
  grid-template-areas: 
  "title"
  "content"
  "content"
  "buttons";
  background-color: var(--white);
  padding: 0px 64px;
}
.wizard-view__stepper {
  grid-area: stepper;
  padding-top: 16px;
}
.wizard-view__content {
  position: relative;
  grid-area: content;
  overflow-y: auto;
  overflow-x: hidden;
  .hmenu-layout-container {
    height: fit-content !important;
  }
}
.wizard-view__page {
  width: 100%;
  height: 100%;
}
.wizard-view__page--hidden {
  display: none;
}
.wizard-view__content__message {
  position: absolute;
  width: 100%;
  bottom: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
}

.wizard-view__content__message--hidden {
  // display: none;
  visibility: hidden;
}

.wizard-view__page-title {
  grid-area: title;
}
.wizard-view__page-title p {
  font-weight: 600;
  font-size: var(--font-size-xxxl);
  line-height: 35px;
  color: var(--black);
  padding: 8px 8px 0px 0px;
}
.wizard-view__buttons {
  grid-area: buttons;
  padding: 8px 0px 36px 0;
}
