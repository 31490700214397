.contactron-commissioning-wizard {
    width: 100%;
    height: 100%;
    
    .page-device-naming {
        width: 100%;
        height: 100%;
        padding-bottom: 16px;
        display: grid;
        grid-template-columns: 54% 2% auto;
        grid-template-rows: 20% auto;
        grid-template-areas:
        "header . image"
        "table . image";

        .page-device-naming__header {
            grid-area: header;
            padding: 0px 8px 0px 8px;
        }
        .page-device-naming__image {
            grid-area: image;
            padding: 8px 8px 0px 8px;
        }
        .page-device-naming__table {
            grid-area: table;
            padding: 0px 16px 24px 8px;
            overflow: auto;
        }
    }
}