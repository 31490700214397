.table-row-station__address {
    text-align: start;
    vertical-align: top;
    padding-top: 10px;
}

.table-row-station__name {
    text-align: start;
    vertical-align: top;
    padding-top: 10px;
    overflow: hidden;
    max-width: 320px;
}

.table-row-station__control {
    text-align: start;
    vertical-align: top;
}
