/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.fwd-inputbox-control {
    height: 35px;
}

.fwd-inputbox-control__input {
  border: none;
  border-bottom: 2px solid var(--dim-gray);
  height: 35px;
  background-color: var(--lighter-gray);
}
 
.fwd-inputbox-control input
{
  color: var(--dark-gray);
  font-size: var(--font-size-lg);
  padding-left: 4px;
}

.fwd-inputbox-control .input-field .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 9px;
}

.textfield__input {
  border-bottom: 2px solid var(--dim-gray);
  height: 35px;
}

.textfield__input--edited {
  background-color: var(--changed-values) !important;
}

.textfield__input:disabled {
  color: darkgray !important;
}

/* input::-webkit-outer-spin-button */
.fwd-inputbox-control .input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
