.linearization-modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 0;
}

.linearization-modal-dialog__container {   
    display: flex;
    flex-direction: column;
    width: 1400px;
    height: 770px;
    background-color: var(--white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 6px
}

.linearization-modal-dialog__container__header {
    height: 70px;
    display: flex;
    justify-content: center;
}

.linearization-modal-dialog__container__header__text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: var(--dark-gray);
    padding-top: 10px;
}

.linearization-modal-dialog__container__header__messagebox {
    position: absolute;
    width: 970px;
    z-index: 20000;
}

.linearization-modal-dialog__container__body {
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
}

.linearization-modal-dialog__container__buttons {
    height: 103px;
    grid-area: "footer";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}

.linearization-modal-dialog__container__buttons__f {
    display: flex;   
    justify-content: space-between;
    height: 56px;
    width: 1315px;
}

.linearization-modal-dialog__container__buttons__close {
    width: 349px;
    background: var(--dark-blue);
    box-shadow: 0px 2px 4px rgba(32, 32, 32, 0.6);
    border-radius: 4px;
    font-size: 19.2px;
    font-weight: 600;
    line-height: 1.27;
}

.linearization-modal-dialog__error-message {
    color: var(--red);
    font-weight: 600;
}

.linearization-modal-dialog__container__message {
    width: 60%;
}