.DragIndicator-container {
  z-index: 9999;
  width: fit-content;
  height: fit-content;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  align-self: stretch;
}

.DragIndicator-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 4px 8px;
}

.DragIndicator-button {
  width: fit-content;
}

.DragIndicator-button-icon {
  width: 22px;
  height: 22px;
}

.DragIndicator-item-text {
  margin-left: 8px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}