.LinearizationComponent {
  width: 1315px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.LinearizationComponent-table {
  height: 100%;
}

.LinearizationComponent-splitter {
  width: 12px;
  height: 100%;
  background: var(--lighter-gray);
  border: none;
}

.LinearizationComponent-graphics {
  flex: 1 1 auto;
  height: 100%;
}

.LinearizationComponent-splitter:focus-visible {
  outline-color: var(--button-border) !important;
}