/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.compare-parameter-view {
    width: 100%;
    height: 100%;
}

.compare-parameter-view__content {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
}

.compare-parameter-view__loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}