/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.input-range-slider-control {
  height: 43px;
}

.input-range-slider-control__label {
  word-break: break-word;
  padding: 0;
}

.input-range-slider-control__label p {
  font-size: var(--font-size-xl);
}

.input-range-slider-control__value__slider {
  width: 50%;
  padding: 0px 8px 0 8px;
}

.input-range-slider-control__value__input__low,
.input-range-slider-control__value__input__high
{
  width: 25%;
}

.input-range-slider-control__value .inputbox-control input
{
  width: 100%;
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  text-align: center;
}

.input-range-slider-control__unit {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: visible;
}

.input-range-slider-control__unit p {
  font-size: var(--font-size-lg);
}

.input-range-slider-control__value.invalid~.helper-text:after {
  content: attr(data-error);
  color: #f51423
}

.input-range-slider-control .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 9px;
}
