.navigationbar-container {
  display: grid;
  height: 100%;
  width: 300px;
  margin: 0px;
  background-color: var(--lighter-gray);
  grid-template-columns: 100%;
  grid-template-rows: 100px auto;
  grid-template-areas:
  "station"
  "nav";
  grid-row-gap: 16px;
  overflow: hidden;
  transition-duration: .7s;
  padding: 4px 4px 0 0;
}

.navigationbar-container--no-station {
  display: grid;
  height: 100%;
  margin: 0px;
  background-color: var(--lighter-gray);
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
  "nav";
  overflow: hidden;
  transition-duration: .7s;
}

.navigationbar-container__station {
  grid-area: station;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navigationbar-container__nav {
  grid-area: nav;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}