/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.inputbox-control {
    height: 35px;
}

.inputbox-control__input {
  border: none;
  border-bottom: 2px solid var(--dim-gray);
  height: 35px;
  background-color: var(--lighter-gray);
}
 
.textfield__input {
  border-bottom: 2px solid var(--dim-gray);
  height: 35px;
}

.inputbox-control input
{
  color: var(--dark-gray);
  font-size: var(--font-size-lg);
  padding-left: 12px;
}

.inputbox-control .input-field .helper-text::after {
  position: absolute;
  top: 36px;
  font-size: 9px;
}

.textfield__input--edited {
  background-color: var(--changed-values) !important;
}

.textfield__input:disabled {
  color: darkgray !important;
}
