/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.parameter-radio-control {
  display: inline-block;
  width: 100%;
  margin-left: 0px;
  overflow: hidden;
}

.parameter-radio-control__container {
  height: 100%;
  display: flex;
  align-items: center;
}

.parameter-radio-control__container input[type="radio"] {
  opacity: 0;
}

.parameter-radio-control__container label::before{
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid var(--corporate-blue);
  border-radius: 50%;
  box-sizing: border-box;
}

.parameter-radio-control__container-false label::before{
  border: 3px solid var(--gray) !important;
}

.parameter-radio-control__container label::after {
  content: "";
  height: 14px;
  width: 14px;
  display: inline-block;
  border: var(--corporate-blue) 8px solid;
  border-radius: 50%;
}

.parameter-radio-control__container label {
  height: 30px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-size-lg);
  font-family: 'IBM Plex Sans';
  padding: 0;
  margin: 0;
  left: 25px;
}

.parameter-radio-control__container label::before,
.parameter-radio-control__container label::after {
  position: absolute;
}

/* Outer-box*/
.parameter-radio-control__container label::before {
  top: 0px;
  left: -37px;
}

/*Checkmark*/
.parameter-radio-control__container label::after {
  left: -30px;
  top: 7px;
}

/*Hide the checkmark by default*/
.parameter-radio-control__container input[type="radio"] + label::after {
  content: none;
}

/*Unhide the checkmark on the checked state*/
.parameter-radio-control__container input[type="radio"]:checked + label::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.parameter-radio-control__container input[type="radio"]:focus + label::before {
  /* outline: var(--corporate-blue) auto 5px; */
  outline: none;
}
