.caparoc-channel-reset-counter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 8px;

    .caparoc-channel-reset-counter__label {
        width: 140px;
        overflow: hidden;        
    }
    .caparoc-channel-reset-counter__button {
        padding-left: 24px;
    }
}