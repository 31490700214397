/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.ipv4-address-container__input {
  width: 100%;
}
.ipv4-address-container__input__inputbox {
  flex-grow: 10;
}
.ipv4-address-container__delimeter {
  padding: 0 4px 0 4px;
  font-weight: 600;
}
