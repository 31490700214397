.tableKaltstelleView-container {
    display: flex;
    flex-direction: column;
    height: 42%;
    width: 100%;
    padding-left: 48px;
}

.tableKaltstelleView-container-title {
    display: flex;
    height: 33px;
    padding-left: 17px;
    padding-top: 8px;
}

.tableKaltstelleView-container-title-label {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--black);
    fill: solid;
    padding-right: 8px;
    overflow: hidden;
}

.tableKaltstelleView-container-header {
    display: flex;
    flex-direction: row;
    height: 33px;
}

.tableKaltstelleView-container-table {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-left: 15px;
}

.tableKaltstelleView-container-table-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    padding-right: 16px;
}

.tableKaltstelleView-container-table-element-label {
    width: 103px;
}

.linearizationTableControl-element-splitter1 {
    width: 4px;
}

.tableKaltstelleView-container-table-element-splitter2 {
    width: 8px;
}


.tableKaltstelleView-container_separator-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 24px;
    width: 100%;
    padding-bottom: 8px;
} 

.tableKaltstelleView-container_separator-container_separator {
    position: flex;
    width: 230px;
    height: 1px;
    border: 1px solid var(--dim-gray);
}