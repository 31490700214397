.h3motorstarter-online-manual-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 16px;
    grid-template-areas: 
        "activate activate"
        "on stop";
    width: 90%;
    overflow: hidden;

    .h3motorstarter-online-manual--activate {
        grid-area: activate;
        padding: 4px;
    }

    .h3motorstarter-online-manual--on {
        grid-area: on;
        padding: 4px;
    }

    .h3motorstarter-online-manual--stop {
        grid-area: stop;
        padding: 4px;
    }
}