/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.page-document-container {
  height: 100%;
  width: 100%;
}

.page-document-container__caption {
  font-size: large;
  font-weight: bold;
}

.page-document-container__document-name {
  font-size: var(--font-size-lg);
  font-weight: 400;
  padding: 0px 0px 0px 0px;
}

.page-document-container__document-text {
  font-size: var(--font-size-lg);
  font-weight: 400;
  padding: 16px 0px 0px 0px;
}

.page-document-container__document-text textarea {
  height: 10em;
  resize: none;
}

.page-document-container__buttons__button Button {
  text-transform: uppercase;
  padding: 8px;
  font-size: var(--font-size-xl);
  font-weight: 600;
  overflow: hidden;
}

