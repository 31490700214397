.download-progress-modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    z-index: 10000;
    padding: 0px;
    width: 100%;
    height: 100%;
}

.download-progress-modal-dialog__container {
    padding-top: 16px;
    padding-left: 40px;
    padding-right: 40px;
    width: 1212px;
    height: 735px;
    border-radius: 8px;
    background-color: var(--white);
}

.download-progress-modal-dialog__container__title {
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
}

.download-progress-modal-dialog__container__row {
  height: 40px;
}

.download-progress-modal-dialog__content__message {
  position: absolute;
  width: 95%;
  bottom: 19%;
  background-color: rgba(255, 255, 255, 0.5);
}

.download-progress-modal-dialog__content__message--hidden {
  display: none;
}