/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.led-link-container {
    display: flex;
    align-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-bottom: 8px;
    width: 100%;
}

.led-link-container_label {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    fill: solid;
}

.led-link-container_label-color {
    color: #167d85;
}

.led-link-container_icon {
    padding-right: 8px;
}

.link-led-container_icon-content {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.link-led-container_icon-content-red {
    background-color: var(--red);
}

.link-led-container_icon-content-yellow {
    background-color: var(--yellow);
}

.link-led-container_icon-contentorange {
    background-color: var(--yellow);
}

.link-led-container_icon-content-green {
    background-color: var(--medium-green);
}

.link-led-container_icon-content-gray {
    background-color: var(--dim-gray);
}

.link-led-container_icon-content-white {
    background-color: var(--white);
}

.link-led-container_icon-content-black {
    background-color: var(--black);
}
