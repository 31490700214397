// .compare-container {
//     display: grid;
//     grid-template-columns: calc(20% - 12px) calc(40% - 12px) 36px calc(40% - 12px);
//     grid-template-rows: 100%;
//     grid-template-areas:
//     "label left center right";
// }
.compare-container__left {
    border: 1px solid var(--white);
    width: 100%;
}

.compare-container__left__label {
    border: 1px solid var(--white);
    width: 30%;
}

.compare-container__left__control {
    border: 1px solid var(--white);
    width: 70%;
}

.compare-container__left__label {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-end;
  word-break: break-word;
  grid-area: label;
}
  
.compare-container__left__label p {
  font-size: var(--font-size-lg);
} 

.compare-container__center {
    width: 100%;      
    height: 100%;
}


.compare-container__control--x-padding {
    padding-left: 12px;
    padding-right: 12px;
}

.compare-container__center__sign {
    color: var(--corporate-blue);
    width: 20px;
}

.compare-container__button {
    padding: 0px 8px !important;
    height: 38px;
    margin: 0px 2px;
    margin-bottom: 2px;
}

.compare-container__button__arrow {
    color: var(--corporate-blue);
    padding: 0;
    margin: 0;
}

.compare-container__button--hidden {
    visibility: hidden;
}
