.MessageBox-container {
  height: 56px;
  width: 50%;
  border-width: 2px 2px 2px 16px;
  border-style: solid;
  flex-shrink: 0;
}

.MessageBox-container-button {
  padding-left: 8px;
  border: none;
  background: none; 
}

.MessageBox-container-text {
  font-family:  "IBM Plex Sans";
  font-weight: 600;
  font-style: normal;
  color: var(--black);
}