/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.input-slider-control {
  height: 35px;
}

.input-slider-control__label {
  word-break: break-word;
  padding: 0;
}

.input-slider-control__label p {
  font-size: var(--font-size-xl);
}

.input-slider-control__value__slider {
  width: 60%;
  padding: 0px 8px 0 0;
}

.input-slider-control__value__input {
  width: 39%;
}

.input-slider-control__unit {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: visible;
}

.input-slider-control__unit p {
  font-size: var(--font-size-lg);
}
