/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

// .w-tpf-p1-container {
//     overflow: auto;
//     height: 500px;
// }

.w-tpf__preview-dataset {
    overflow: auto;
}
