.buttonwithdescription-container {
    width: 100%;
    display: flex;
    flex-direction: column;
 
    .buttonwithdescription-title {
        font-weight: 600;
        font-size: 24px;
    }

    .buttonwithdescription-text {
        font-weight: 400;
        font-size: 16px;
        padding-top: 16px;
    }

    .buttonwithdescription-button {
        padding-top: 24px;
    }
}
