.caparoc-channel-rotary-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.caparoc-channel-rotary-switch--red {
    background-color: var(--red);
}

.caparoc-channel-rotary-switch--green {
    background-color: var(--green);
}