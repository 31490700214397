.sport-specific {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "block image";

    .sport-specific-block {
        grid-area: block;
        padding: 32px;
    }

    .sport-specific-image {
        grid-area: image;
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding-top: 36px;
        img {
            height: 408px;
        }
    }
}
