.device-process-data-container {
  width: 100%;
  height: 100%;
  padding: 8px 16px 0 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: rgba(242, 244, 246, 1);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 32px 24px auto 64px;
  grid-template-areas:
    "header"
    "progress"
    "select"
    "applyForAllDevices";

  .device-process-data-container__header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 21px;
    font-weight: 400;

    .device-process-data-container__header-max {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }
  }
  .device-process-data-container__device_progress {
    grid-area: progress;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    .device-process-data-container__device_progress-bar {
      width: 212px;
    }
  }
  .device-process-data-container__select {
    grid-area: select;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    padding-bottom: 8px;
  }

  .device-process-data-container__apply-for-all-devices {
    grid-area: applyForAllDevices;
    background-color: var(--lighter-gray);
    display: flex;
    align-items: center;
  }
}
