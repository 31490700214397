.compare-parameter-view-layout {
  display: grid;
  grid-template-columns: calc(50% - 18px) 36px calc(50% - 18px);
  grid-template-rows: 100%;
  grid-template-areas:
  "left center right";
}

.compare-parameter-view-layout__left {
  grid-area: left;
}

.compare-parameter-view-layout__right {
  grid-area: right;
  // border: 1px solid green;
  // background-color: lightgreen;
}

.compare-parameter-view-layout__center {
  grid-area: center;
  // border: 1px solid blue;
  // background-color: lightblue;
}

.compare-parameter-view-layout__control--x-padding {
  padding-left: 12px;
  padding-right: 12px;
}