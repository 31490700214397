/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
$max-column-number: 12;
$max-row-number: 12;
$grid-item-padding: 8px;

.grid-layout-container {
   width: 100%;
   height: 100%;
   display: grid; 
   grid-auto-rows: 1fr;
   grid-auto-columns: 1fr;
   overflow: hidden;      

   .grid-layout-container__item {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: $grid-item-padding;
   }
}

@for $i from 1 through $max-column-number {
  .grid-layout-container--columns-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

@for $i from 1 through $max-row-number {
  .grid-layout-container--rows-#{$i} {
     grid-template-rows: repeat($i, 1fr);
  }
}
