$max-column-number: 11;

.cockpit-caparoc-station__container {
  display: grid;
  height: 100%;
  width: 100%;
  padding: 0 8px 8px 0;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 16px;
  grid-template-rows: 35% 65%;
  grid-template-areas:
  "systemStatus systemData systemSettings"
  "modules modules modules";
}

.cockpit-caparoc-station__systemStatus {
  grid-area: systemStatus;
  width: 100%;
  height: 100%;
}

.cockpit-caparoc-station__systemData {
  grid-area: systemData;
  width: 100%;
  height: 100%;
}

.cockpit-caparoc-station__systemSettings {
  grid-area: systemSettings;
  width: 100%;
  height: 100%;
}

.cockpit-caparoc-station__modules {
  grid-area: modules;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
  padding-bottom: 16px;
}

@for $i from 1 through $max-column-number {
  .cockpit-caparoc-station__grid-column-start-#{$i} {
    grid-column-start: $i;
  }
}

@for $i from 1 through $max-column-number {
  .cockpit-caparoc-station__grid-column-end-#{$i} {
    grid-column-end: $i;
  }
}

.cockpit-caparoc-station__grid-row-1 {
  grid-row: 1;
}

.cockpit-caparoc-station__grid-row-2 {
  grid-row: 2;
}