.contactron-layout-card__header-icon-container {
    width: 36px;
    display: flex;
    justify-content: flex-end;
  }

  .contactron-layout-card__header-icon-container-icon {
    width: 24px;
    height: 24px;
  }

  .contactron-layout-card__header-icon-container-icon:focus-visible {
    outline-color: var(--button-border) !important;
  }

  .contactron-layout-card__header-overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .contactron-layout-card__header-overlay-container {
    z-index: 9999;
    width: 168px;
    background: var(--white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    align-self: stretch;
    padding: 4px;
  }
  