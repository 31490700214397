.x3phasen-chart {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 1fr);
    width: 100%;
    height: 100%;

    .x3phasen-chart__element {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 8px;

        .x3phasen-chart__element__label {
            width: 24px;
            font-weight: 700;
        }

        .x3phasen-chart__element__chart {
            width: 100%;
        }
        
        .x3phasen-chart__element__value {
            width: 128px;
            font-weight: 700;
        }
    }
}
