.simulation-settings-dialog__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    position: absolute;
    z-index: 10000;
    padding: 0px;
    width: 100%;
    height: 100%;

    .simulation-settings-dialog {
      width: fit-content;
      height: fit-content;

      .simulation-settings-dialog__container {
        padding-top: 48px;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 16px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: var(--white);

        .simulation-settings-dialog__container_settings {
          overflow: auto;
        }
      }
    
    .simulation-settings-dialog__container__row {
      height: 40px;
    }
  }
}
