.thumbwheel-button {
  border-radius: 20px !important;
  height: 40px;
  width: 40px;
  padding: 0;

  &:hover {
    border-radius: 20px !important;
  }
  
  &:not(:disabled):not(.disabled):active {
    border-radius: 20px !important;
  }
  
  img {
    margin-top: -5px;
  }
}

