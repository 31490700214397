.page-device-header {
    width: 100%;
    display: flex;
}

.page-device-header__label {
    padding-left: 8px;
    font-weight: 600;
    font-size: var(--font-size-xl);
    text-align: start;
}

.page-device-header__cardcatalog {
    width: 100%;
    margin: 0;
    padding-right: 0;
    padding-left: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.page-device-header__spliter {
    width: 100%;
    border-bottom: 0.1em solid var(--light-gray);
}